// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Card,
  Divider,
  Menu,
  Checkbox,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  ClickAwayListener,
  Avatar
} from "@material-ui/core";

import { styled, ThemeProvider } from "@material-ui/core/styles";




import ReservationsController, {
  Props,
  configJSON,
  Item,
} from "./ReservationsController";
import { boxShadows, theme } from "../../../components/src/theme";
import PetOwnerNavigationMenu from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
import Footer from "../../../components/src/Footer.web";
import CustomRating from "../../../components/src/CustomizedComponents.web";
import { Dog1 } from "../../landingpage/src/assets";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CustomDate from "../../../components/src/CustomizeCalender";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import CustomsettingModal from "../../../components/src/CustomSettingsmodal";
import { plusIcon, threeDots } from "../../shoppingcart/src/assets";
import Loader from "../../../components/src/Loader.web";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
import { AdditionalServicesforPets } from "./types";
import moment from "moment";
import { getReviewMessage } from "../../../components/src/CommonFunctions";





export default class Reservations extends ReservationsController {
  constructor(props: Props) {
    super(props);
  }


  renderButtons = () => {
    return (
      <>
        <Box style={webStyle.buttonsbox as React.CSSProperties}>
          <Button style={webStyle.editbutton as React.CSSProperties}
            data-test-id="Editbtn"
            onClick={() => this.OnShoppingCart()}
          >Edit reservation</Button>
          <Button style={webStyle.cancelbutton as React.CSSProperties}
            data-test-id="Cancelbtn"
            onClick={() => this.setState({ iscancelVisible: true })}
          >Cancel the reservation</Button>



        </Box>
        {this.state.reservationData.attributes.hotel_information.pet_type === "daycare_services" &&
          <>
            {this.state.reservationData?.attributes.day_care_service_type === "Repeat_Weekly" && <Grid item xs={12}>
              <Grid container direction="row" spacing={2} alignItems="center" style={webStyle.weekLayout}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" style={webStyle.headingText}>{configJSON.selectedDates}</Typography>

                </Grid>
                {this.state.reservationData?.attributes.blocked_days && this.state.reservationData?.attributes.blocked_days.map((days: string) => {
                  return (<Grid item xs={12} sm={5}>
                    <Typography variant="body1"> {this.getWeekOfDay(days)}, {moment(days).format("DD MMMM YYYY")}</Typography>

                  </Grid>)
                })}

              </Grid>

            </Grid>}
          </>
        }
        <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>


          {this.state.serviceKey === "standard" ? this.renderPolicy() : this.renderPolicy1()}

        </Box>
      </>
    )
  }

  renderAdditionalSerivice = (service: any, additionalarray: any, onchange: any, anchorEl: any) => {
    return (
      <>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl as Element | null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => this.setState({ anchorElCatservice: null, anchorElDodservice: null })}
          data-test-id="handleSelectClose"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          PaperProps={{
            style: {
              width: "41.5%",
              padding: "0px 15px",
              cursor: "pointer",
              maxHeight: "300px",
              overflowY: "auto",
              scrollbarColor: "transparent",
              scrollbarWidth: "thin",
              marginTop: "5px",
              borderRadius: "8px",
              borderBottomRightRadius: "50% !important"
            },
          }}
        >

          {additionalarray.map((value: { price: string; service: string; }) => (
            <React.Fragment >
              <Grid item style={{ ...webStyle.additionalgrid, backgroundColor: service.includes(value.service) ? "#E0F4FC" : "transparent" }}>
                <Grid item style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    data-test-id='checkBoxSelectServices'
                    onChange={(event) => onchange(event)}
                    value={`${value.service}`}
                    inputProps={{
                      "aria-label": "checkbox with default color",
                    }}
                    color="default"
                    style={{ padding: "0px" }}
                  />
                  <Typography variant="body1" color="textSecondary">{value.service}</Typography>
                </Grid>
                <Typography variant="body1">
                  {value.price}
                </Typography>
              </Grid>
              <Divider style={{ backgroundColor: "#326478" }} />
            </React.Fragment>
          ))}

          <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <Button
              data-test-id="continuecat"
              onClick={() => this.setState({ anchorElDodservice: null, anchorElCatservice: null })}
              style={{ ...webStyle.savebuttonstyle, textDecorationLine: "underline", margin: "22px" } as React.CSSProperties}>Continue</Button>
          </Box>

        </Menu>
      </>
    )

  }

  getServiceCalculation = (value: { service_provided_type: string; charge_type: string; price: number; name: string; }, catcount1: number, dogcount1: number) => {
    if (value.service_provided_type === "OneTime" && value.charge_type !== "All") {
      return (
        <>
          <Typography style={webStyle.calculationText}>{`£${value.price} ${value.name} for ${this.getPetCount(catcount1, dogcount1)} pets`}</Typography>
          <Typography style={webStyle.calculationText}>{`£${this.getPrice(value.price, catcount1, dogcount1, 1)}`}</Typography>
        </>
      );
    } else if (value.service_provided_type === "Daily" && value.charge_type !== "All") {
      return (
        <>
          <Typography style={webStyle.calculationText}>{`£${value.price} ${value.name} for ${this.getPetCount(catcount1, dogcount1)} pets`}</Typography>
          <Typography style={webStyle.calculationText}>{`£${this.getPrice(value.price, catcount1, dogcount1, 1)}`}</Typography>
        </>
      );
    }
  }

  additionalServiceDetails = (value: AdditionalServicesforPets, dogcount1: number, catcount1: number, daysDifference: number) => {
    if (value.service_provided_type === "Daily" && value.isAllPets === "Per Pet") {
      return (
        <>
          <div style={webStyle.perPetFlow}>
            <Typography style={webStyle.calculationText}>{`£${value.service.price} ${value.service.name} for ${value.pets.length} pets X ${daysDifference} Days`}</Typography>
            <Typography style={webStyle.calculationText}>{this.getPetsNames(value.pets)}</Typography>
          </div>
          <Typography style={webStyle.calculationText}>{`£${this.getServiceTotalDaily(value.service.price, value.pets.length, daysDifference)}`}</Typography>
        </>
      )
    } else if (value.service_provided_type === "OneTime" && value.isAllPets === "Per Pet") {
      return (
        <>
          <div style={webStyle.perPetFlow}>
            <Typography style={webStyle.calculationText}>{`£${value.service.price} ${value.service.name} for ${value.pets.length} pets`}</Typography>
            <Typography style={webStyle.calculationText}>{this.getPetsNames(value.pets)}</Typography>
          </div>
          <Typography style={webStyle.calculationText}>{`£${this.getServiceOneTime(value.service.price, value.pets.length)}`}</Typography>
        </>
      )
    } else if (value.service_provided_type === "OneTime" && value.isAllPets === "All Pets") {
      return (
        <>
          <Typography style={webStyle.calculationText}>{`£${value.service.price} ${value.service.name} for ${this.getPetCount(catcount1, dogcount1)} pets `}</Typography>
          <Typography style={webStyle.calculationText}>{`£${this.getServiceTotalDaily(value.service.price, this.getPetCount(1,0), 1)}`}</Typography>
        </>
      )
    } else {
      return (
        <>
          <Typography style={webStyle.calculationText}>{`£${value.service.price} ${value.service.name} for ${this.getPetCount(catcount1, dogcount1)} pets X ${daysDifference} Days`}</Typography>
          <Typography style={webStyle.calculationText}>{`£${this.getServiceTotalDaily(value.service.price, this.getPetCount(1,0), daysDifference)}`}</Typography>
        </>
      )
    }
  };

  renderClaculation = () => {
    const { reservationData, totalcalculationdays } = this.state
    const totalDays = this.state.reservationData.attributes.hotel_information.pet_type === "daycare_services" ?
      this.getDays() :
      this.state.totalcalculationdays;
    const selected_room_dog1 = this.state.dogRoomData || []



    const selected_room_cat1 = this.state.catRoomData || []

    const dogcount1 = (reservationData?.attributes.number_of_dogs)
    const catcount1 = (reservationData?.attributes.number_of_cats)
    const selectedServicePets: AdditionalServicesforPets[] = reservationData?.attributes?.additional_services_for_dog || [];
    const ownServicePets: AdditionalServicesforPets[] = reservationData?.attributes?.own_services_for_dog || [];
    const stripeAmount = reservationData.attributes.total_charges;

    return (
      <>
        {selected_room_dog1.length > 0 && selected_room_dog1.map((value: any) => {
          
          return (
            <>
              <Box style={{ width: "95%", padding: "5px 5px", marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
                <Typography style={webStyle.calculationText}>{`£${value.price} ${value.name} for dog X ${totalcalculationdays} Days`}</Typography>
                <Typography style={webStyle.calculationText}>{`£${this.getRoomPrice(value.price, totalcalculationdays)}`}</Typography>
              </Box>
            </>
          )
        })}


        {selected_room_cat1.length > 0 && selected_room_cat1.map((value: any) => {
          return (
            <>
              <Box style={{ width: "95%", marginTop: "5px", display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>
                <Typography style={webStyle.calculationText}>{`£${value.price} ${value.name} for cat X ${totalcalculationdays} Days`}</Typography>
                <Typography style={webStyle.calculationText}>{`£${this.getRoomPrice(value.price, totalcalculationdays)}`}</Typography>
              </Box>
            </>
          )
        })}


        {this.state.reservationData.attributes.hotel_information.pet_type === "daycare_services" &&
          <Box style={webStyle.calculationBox}>
            <Typography style={webStyle.calculationText}>
              {`£${this.state.displayPrice} X ${this.state.reservationData.attributes.pets.data.length} Dog(s) ${this.getNumberOfWeeks()}`}
            </Typography>
            <Typography style={webStyle.calculationText}>
              {`£${this.getDayCarePrice()}`}
            </Typography>
          </Box>
        }




        {selectedServicePets.length > 0 && selectedServicePets.map((value: AdditionalServicesforPets) => {
          return (
            <>
              <Box style={{ padding: "5px 5px", marginTop: "5px", width: "95%", display: "flex", justifyContent: "space-between" }}>
                {this.additionalServiceDetails(value, dogcount1, catcount1, totalDays)}
              </Box>
            </>
          )
        })}

        {ownServicePets.length > 0 && ownServicePets.map((value: AdditionalServicesforPets) => {
          return (
            <>
              <Box style={{ padding: "5px 5px", marginTop: "5px", width: "95%", display: "flex", justifyContent: "space-between" }}>
                {this.additionalServiceDetails(value, dogcount1, catcount1, totalDays)}
              </Box>
            </>
          )
        })}

        {stripeAmount && <Box style={webStyle.calculationBox}>
          <Typography style={webStyle.calculationText}>
            {configJSON.transactionFee}
          </Typography>
          <Typography style={webStyle.calculationText} data-test-id="charges">
            {`£${stripeAmount}`}
          </Typography>
        </Box>}
        <Divider style={{ backgroundColor: "#B2E4F8", margin: "10px 8px" }} />
        <Box style={{ width: "95%", padding: "10px 5px", marginTop: "20px", borderRadius: "8px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{ fontSize: "14px", fontWeight: 700 }}>Total</Typography>
          <Typography style={webStyle.calculationText}>
            {this.getTotalPriceRes(reservationData.attributes.price, stripeAmount)}
          </Typography>


        </Box>

      </>
    )

  }

  renderPolicy = () => {
    return (
      <>
        {this.state.standardCancellation.length > 0 &&
          this.state.standardCancellation.map((value: any) => {
            return (
              <>
                <div
                  style={{ fontFamily: "inter", fontWeight: 400 }}
                  dangerouslySetInnerHTML={{
                    __html: value?.attributes?.description
                  }}
                />
              </>
            )

          })
        }
      </>
    )
  }

  renderPolicy1 = () => {
    return (
      <Box style={{ marginLeft: "10px" }}>



        <Typography>1. Until <span style={webStyle.spandata}>{this.state.tailoredCancellation?.free_cancellation_days} days</span> the pet owner can cancel and amend their reservation free of charge</Typography>
        <Typography>2. The pet owner will be charged <span style={webStyle.spandata}>{this.state.tailoredCancellation?.pre_payment_percent}%</span> as pre-payment</Typography>
        <Typography>3. We will keep <span style={webStyle.spandata}>100%</span> of the total booking amount if pet owner does not turn up on the day</Typography>
      </Box>
    )
  }




  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ width: "100%", padding: "0px" }}>
          <CustomizedSnackbars toasterType={this.state.toasterType} openToaster={this.state.toasterStatus}
            message={this.state.toasterMessage}
            duration={2000}
            closeStatus={this.closeToaster} />
          <NavbarBox style={{ zIndex: 6, position: "relative" }}>
            <PetOwnerNavigationMenu
              navigation={this.props.navigation} id=""
              activeLink={6} navType="fullNavbar" />
          </NavbarBox>

          <EditTableBox >
            <Box className="headingBox">
              <Button
                onClick={() => this.props.navigation.navigate("ReservationHistory")}
              >
                <ArrowBackIosOutlinedIcon />
              </Button>
              <Typography style={{ fontWeight: 700, fontSize: "24px" }}>Edit the reservation and confirm</Typography>
            </Box>
            <Box className="editDetails">
              <Box className="contentbox">
                {this.renderButtons()}
              </Box>
              <Box className="mainCard">
                <Card className="cardLayout">
                  <img src={this.state.reservationData?.attributes?.images[0]?.url}
                    style={webStyle.media as React.CSSProperties}
                    data-test-id='error_image' />
                  <Grid item xs={12} style={{ width: '420px' }}>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h6" style={webStyle.hotelName}>{this.state.reservationData?.attributes?.hotel_information?.name}</Typography>
                      </Grid>
                      <Grid item xs={12} container direction="row" alignItems="center">
                        <LocationOnOutlinedIcon />
                        <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>
                          {this.state.reservationData?.attributes?.hotel_information?.hotel_city},
                          {this.state.reservationData?.attributes?.hotel_information?.hotel_country_region}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} >
                        <Grid container justifyContent="space-between">
                          <Grid item xs={6} sm={6} >
                            <Typography variant="h6" style={webStyle.hotelName}>£{this.getDisaplyPrice(this.state.reservationData?.attributes)}</Typography>
                            <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>Starting from per day</Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} >
                            <Grid container justifyContent="flex-end" spacing={1} direction="column">
                              <Grid item>
                                <Box display={'flex'} alignItems={'center'} justifyContent="flex-end">
                                  <Typography variant="body1" color="textSecondary" style={{ ...webStyle.locationName, height: "0px", marginRight: "5px" }}>{this.state.reservationData?.attributes?.hotel_information?.rating || 0}</Typography>
                                  <CustomRating rating={this.state.reservationData?.attributes?.hotel_information?.rating || 0} />
                                </Box>
                              </Grid>
                              <Grid item>
                                <Box display={'flex'} alignItems={'center'} justifyContent="flex-end">
                                  <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>{getReviewMessage(this.state.reservationData?.attributes?.hotel_reviews_count)}</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Box sx={webStyle.hoteldescribe}>
                    <Box style={webStyle.calculationbox2}>
                      {this.renderClaculation()}
                    </Box>

                  </Box>
                </Card>

              </Box>
            </Box>


          </EditTableBox>

          <CustomsettingModal
            isVisible={this.state.iscancelVisible}
            hideModal={this.cancelclose}
            callApi={this.handleCancelReservation}
            typography1="Cancel Reservation"
            typography2="Are you sure you want to cancel this reservation?"
            buttontext1="Yes"
            buttontext2="No"
          />

        </Container>
        <Footer color="#CCEDFB" footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />
        <Loader loading={this.state.ReservationLoader} />
      </ThemeProvider>

    );
  }
}

const EditTableBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBottom: "200px",
  background: "#CCEDFB",
  minHeight: "100vh",
  marginTop: "100px",
  "& .headingBox":{
    width: "80%",
    backgroundColor: "#fff",
    height: "110px",
    zIndex: 4,
    boxShadow: "rgba(67, 134, 161, 0.15) 0px 4px 8px 0px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(600)]: {
      width: "90%",
    }
},
  "& .editDetails":{
    display: "flex",
    width: "80%",
    columnGap: "30px",
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      width: "90%",
      columnGap: "0px"
    }
  },
  "& .contentbox": {
    backgroundColor: "#fff",
    width: "60%",
    borderRadius: "0px 0px 8px 8px",
    height: "min-content",
    padding: "20px 10px",
    [theme.breakpoints.down(600)]: {
      width: "95%",
    },
  },
  "& .mainCard":{
    backgroundColor: "#fff",
    height: "max-content",
    zIndex: 5,
    borderRadius: "0px 0px 8px 8px",
    [theme.breakpoints.down(600)]: {
      marginTop:'40px',
    }
  },
  "& .cardLayout":{
    marginTop: "-70px",
    marginBottom: 30,
    padding: "0px 20px",
    boxShadow: "none",
    [theme.breakpoints.down(600)]: {
      marginTop:'0px',
    }

  },

}));

const NavbarBox = styled(Box)({
  "& header": {
    boxShadow: "none"
  }
})

const CustomCalendar1 = styled(Box)({

  "& .rmdp-input": {
    backgroundColor: "#E0F4FC",
    height: "40px",
    width: "265%",
    borderRadius: "8px",
    border: "solid 1px #B2E4F8",
    fontFamily: "inter"
  },
  "& .rmdp-header": {
    backgroundColor: "#CCEDFB",
    borderRadius: 8,
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "inter"
  },
  "& .rmdp-week-day": {
    color: "#000",
    fontFamily: "inter"
  },
  "& .rmdp-day.rmdp-selected span:not(.highlight) ": {
    backgroundColor: "#EA0C78",
    color: "#fff",
    fontFamily: "inter"
  },
  "& .rmdp-day.rmdp-today span": {
    backgroundColor: "#fff",
    color: "#000",
    fontFamily: "inter"
  },
  "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover": {
    backgroundColor: "#EA0C78",
    color: "#fff",
    borderColor: "#EA0C78",
    fontFamily: "inter"
  },
  "& .rmdp-arrow": {
    borderColor: "#EA0C78"
  },

  "& .rmdp-arrow-container.disabled .rmdp-arrow, .rmdp-arrow-container.disabled:hover .rmdp-arrow": {
    borderColor: "#EA0C78",
    fontFamily: "inter"
  }

})

const webStyle = {
  perPetFlow: {
    display: "flex" as "flex",
    flexDirection: "column" as "column"
  },
  totalCalculation: {
    fontSize: "14px",
    fontWeight: 400
  },
  calculationBox: {
    padding: "5px 5px",
    marginTop: "5px",
    width: "95%",
    display: "flex",
    justifyContent: "space-between"
  },
  calculationText: {
    fontSize: "14px",
    fontWeight: 700,
    textWrap: "balance",
    marginRight: "4px"
  },
  mainWrapperStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "200px",
    background: "#CCEDFB",
    minHeight: "100vh",
    marginTop: "100px"
  },
  headingbox: {
    width: "80%",
    backgroundColor: "#fff",
    height: "110px",
    zIndex: 4,
    boxShadow: "rgba(67, 134, 161, 0.15) 0px 4px 8px 0px",
    display: "flex",
    alignItems: "center",

  },
  contentbox: {
    backgroundColor: "#fff",
    width: "60%",

    borderRadius: "0px 0px 8px 8px",
    height: "min-content",
    padding: "20px 10px"

  },
  calculationbox: {
    backgroundColor: "#fff",
    height: "max-content",
    zIndex: 5,
    borderRadius: "0px 0px 8px 8px"
  },
  buttonsbox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    minHeight: "180px"
  },
  editbutton: {
    width: "55%",
    height: "56px",
    backgroundColor: "#EA0C78",
    color: "#fff",
    fortSize: "16px",
    fontWeight: 700,
    textTansform: "none",
    borderRadius: "8px",
    textTransform: "none"
  },
  cancelbutton: {
    width: "55%",
    height: "56px",
    border: "1px solid #EA0C78",
    color: "#EA0C78",
    fortSize: "16px",
    fontWeight: 700,
    textTansform: "none",
    borderRadius: "8px",
    textTransform: "none"
  },
  cardLayout: {
    marginTop: "-70px",
    marginBottom: 30,
    padding: "0px 20px",
    boxShadow: "none",

  },
  hotelName: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    minHeight: 28
  },
  locationName: {
    fontWeight: theme.typography.fontWeightRegular,
    minHeight: 24
  },
  font14400: {
    fontSize: 14,
    fontFamily: "FontFamily(Font(R.font.inter))",
    fontWeight: 400,
    marginRight: 10
  },
  font14700: {
    fontSize: 14,
    fontFamily: "FontFamily(Font(R.font.inter))",
    fontWeight: 700,
  },
  hoteldescribe: {
    maxWidth: 522,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "spaca-between",
    // backgroundcolor: "yellow",
    gap: 20
  },
  media: {
    height: 244,
    borderRadius: 8,
    maxWidth: 420,
    width: '100%',
    margin: 'auto',
    objectFit: 'cover'
  },
  cardPosition: {
    position: 'relative',
    top: '-70px',
    [theme.breakpoints.down('xs')]: {
      top: '0px',
    }
  },
  calculationbox2: {
    width: 420,
    // height: 190,
    border: "1px solid #B2E4F8",
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    // padding: "20px",

  },
  Editbox: {
    width: "90%",
    // height:"500px",
    backgroundColor: "#fff",
    borderRadius: "0px 0px 8px 8px",
    padding: "40px 20px"
  },
  labeledit: {
    fontWeight: 700,
    fontSize: "14px",
    color: "#334155",
    fontFamily: "inter",
    marginBottom: "5px",
  },
  breedselect: {
    height: "38px",
    padding: "0px 10px",
    width: "98%",
    borderRadius: "8px",
    border: "1px solid #B2E4F8",
    position: "relative",
    backgroundColor: "#E0F4FC",
    justifyContent: "space-between",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
  },
  additionalselect: {
    height: "58px",
    padding: "0px 10px",
    width: "98%",
    borderRadius: "8px",
    border: "1px solid #B2E4F8",
    position: "relative",
    backgroundColor: "#E0F4FC",
    justifyContent: "space-between",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    marginTop: "10px"
  },
  countrySpan: {
    color: "#000",
    fontSize: "16px",
    fontWeight: 400, fontFamily: "inter",
    textTransform: "capitalize"
  },

  style5: {
    margin: "10px 0px",
    width: "102.5%"
  },

  style6: {
    width: "102.5%",
    margin: "25px 0px",
  },

  footerbuttons: {
    width: "104%",
    height: "44px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  backbuttonstyle: {
    textTransform: "none",
    fontSize: "18px",
    fontWeight: 700,
    color: "#74748B"
  },
  savebuttonstyle: {
    textTransform: "none",
    fontSize: "18px",
    fontWeight: 700,
    color: "#EA0C78"
  },
  additionalgrid: {
    display: "flex", height: "50px", alignItems: "center", padding: "0px 10px", justifyContent: "space-between",

  },
  modalPopup: {
    // position: "absolute",
    zIndex: 9,
    width: "100%",
    minHeight: "170px",
    height: 'object-fit',
    borderRadius: 8,
    backgroundColor: "#ffffff",
    boxShadow: boxShadows.whiteShadow,
  },
  modalPopupContent: {
    padding: "2rem "
  },
  petInfoText: {
    fontWeight: theme.typography.fontWeightRegular
  },
  addPetsText: {
    fontWeight: theme.typography.fontWeightBold
  },
  petListBox: {
    width: 630,
    height: 72,
    padding: "4px, 10px, 4px, 10px",
    gap: 20,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    boxShadow: boxShadows.blueShadow,
    marginBottom: 10
  },
  headingText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black
  },
  headingBlack: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightRegular
  },
  spandata: {
    fontWeight: 700,
    color: "#000"
  },
  weekLayout: {
    margin: '10px 0px',
    padding: '5px 10px'
  }

};

// Customizable Area End
