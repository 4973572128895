import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";

export interface TransactionsRoot {
  transactions: Transactions
}

export interface Transaction {
  attributes: TransactionsAttributes
}
export interface Transactions {
  data: TransactionsData[]
}

export interface Pets {
  attributes: {
    name: string,
    pet_type: string
  }
}

export interface TransactionsData {
  id: string
  type: string
  attributes: TransactionsAttributes
}

export interface  TransactionsAttributes {
  id: number
  transaction_type: string
  booking_id: number
  description: string
  amount?: string
  created_at: string
  updated_at: string
  hotel: string
  order_status: string
  status:string;
  room_type: RoomType
  pet_owner: PetOwner
  order_date: string
}

export interface RoomType {
  dog_rooms: string[]
  cat_rooms: string[]
}

export interface PetOwner {
  first_name: string
  surname: string
  full_name: string
  email: string
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  transactions:TransactionsData[];
  totalPageCount:number
  pageNo:number
  totalPagesCount:number,
  totalCount:number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CollecttransactionfeesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdGetTransactionList:string=""
  csvDownloadId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      transactions:[],
      totalPageCount:0,
      pageNo:1,
      totalPagesCount:0,
      totalCount:0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);


    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if(apiRequestCallId===this.apiCallIdGetTransactionList){
      if(responseJson&&!responseJson.errors){
          this.setState({transactions:responseJson?.transactions?.data,
            totalPageCount: responseJson?.total_page,
            totalCount:responseJson?.total_count
          })
      }
    }

    if(apiRequestCallId===this.csvDownloadId){
      if(responseJson&&!responseJson.errors){
        let reservationArray: string[][] = [];
        reservationArray.push([`Order Id, Order Date, Property name, Room Type, Price, Status`])
        responseJson.transactions.data.forEach((transactionData: Transaction) => {          
          let reservationRow: string[] = [];
          reservationRow.push(`${transactionData.attributes.booking_id}, ${transactionData.attributes.order_date}, ${transactionData.attributes.hotel}, ${this.getRoomType(transactionData.attributes.room_type)}, ${transactionData.attributes.amount}, ${this.getTransactionStatus(transactionData.attributes.status, transactionData.attributes.transaction_type)}`.replace(/#/g, ""));          reservationArray.push(reservationRow);
        })
        const csvContent = reservationArray.map(rowArray => rowArray.join(",")).join("\r\n");
        const bom = "\uFEFF";
        const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${bom}${csvContent}`);
        const csvLink = document.createElement("a");
        csvLink.setAttribute("href", encodedUri);
        csvLink.setAttribute("download", "Transactions");
        document.body.appendChild(csvLink);
        csvLink.click();
      }
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getTransactionsList()
  }

  getTransactionsList = async() => {
    let tokenValue = await getStorageData("authToken");
    
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token:tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdGetTransactionList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}?page=${this.state.pageNo}&per_page=9`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTransactionsList
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ pageNo: value }, () => {
      this.getTransactionsList()
    })
  }
  getRecordPerPage = (resultsPerPage: number) => {
    return ((this.state.pageNo - 1) * resultsPerPage + 1) + (Math.min(this.state.pageNo * resultsPerPage, this.state.transactions.length));
  };

  selectExport = async() => {
    this.csvDownloadId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getTransactionsList,
      endPoint: configJSON.transactionApiEndPoint+"?export_csv=true"
    });  
  };

  apiCall = async (apiData: APIPayloadType) => {
    const { method, endPoint } = apiData;

    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getRoomType = (roomType: {dog_rooms: string[], cat_rooms: string[]})=>{
    const roomList = [...roomType.dog_rooms, ...roomType.cat_rooms];
    return roomList.join(" ");
  };

  getTransactionStatus = (status: string,transaction_type:string) => {
    let message;
    if (status === 'checked_in') {
      message="Payment Completed"
    } else if (status === 'pending') {
      message="Partial Payment Completed"
    } else if (status === 'cancelled'&& transaction_type === 'refund') {
      message="Cancelled, Refunded to Pet Owner"
    }else if (status === 'cancelled' && transaction_type === 'Non_Refundable') {
      message="Canceled, Transferred to connected account "
    } else if (status === 'no_show') {
      message="No Show"
    }else if(status ==='transfer to connected account') {
      message="Transfer to connected account"
    }else if(status ==='free amendment,refund') {
      message="Free amendment refund"
    }else {
      message=""
    }
    return message;
  }


  // Customizable Area End
}
