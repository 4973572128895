import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { DateObject } from "react-multi-date-picker";
import { WithStyles } from "@material-ui/core"
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import React from "react";
import { handleNavigation } from "../../../components/src/CommonFunctions";

interface Room {
  id: number;
  price: number;
  max_capacity: number;
  additional_charges: number;
  account_id: number;
  hotel_id: number;
  room_type: string;
  pet_count: number;
  add_pet: number;
  created_at: string;
  updated_at: string;
  description: string;
  room_for: string;
  num_rooms: string;
  check_in_start: string;
  check_in_end: string;
  check_out_start: string;
  check_out_end: string;
  min_stay: number;
  max_stay: number;
  advance_notice: string;
  notice_before: string;
  pet_room_prices: string[];
  num_dogs: number;
  num_cats: number;
  status: string;
}

export interface SelectedHotel {
  roomId: number;
  name: string;
  bookings: number;
  price: string;
}
export interface HotelDetails {
  data: HotelData
}

export interface HotelData {
  id: string
  type: string
  attributes: HotelAttributes
}

export interface RoomDetails {
  id: number;
  count: string;
  name: string;
  status: string;
}
interface LinkObject {
  url: string;
  title: string;
}

export interface HotelAttributes {
  id: number
  name: string
  description?: string
  address: string
  account_id: number
  check_in: string
  check_out: string
  hotel_type: string
  room_type: string
  phone_number: number
  latitude: any
  longitude: any
  property_name: string
  license_number: string
  country_region: string
  street_address?: string
  flat_suite: string
  city_town?: string
  postcode: string
  email: string
  daycare_price: string
  title: string
  budget_per_day: any
  rating: any
  service_type: string
  additional_services: string[]
  images: Image[]
  documents: Document[]
  reviews_count: number
  address_line_2: string;
  country: string;
  surname: string;
  first_name: string;
  owner_details: any;
  check_in_start: string
  check_in_end: string
  check_out_start: string
  check_out_end: string;
  check_in_check_out_times_present: boolean
  ["Types of rooms"]: RoomDetails[];
  representative_details: any[];
  day_care_services: {
    data: [
      {
        attributes: {
          price_for_one_day: string
        }
      }
    ]
  };
  hotel_address_line_1: string,
  hotel_address_line_2: string,
  hotel_postcode: any,
  hotel_country_region: string,
  hotel_city: string,
  hotel_country: string,
}

export interface Image {

  id: number;
  url: string;
  is_cover_image: boolean
}

export interface Document {
  id: number
  url: string
  filename: string
  content_type: string
}
export interface Services {
  id: number,
  serviceName: string;
  selected: boolean,
  servicePrice: string
}

export interface DayCareService {
  id: number;
  type: string;
  attributes: {
    spaces: string | null;
    price_for_one_day: string | null;
    price_for_two_days: string | null;
    price_for_three_days: string | null;
    price_for_four_days: string | null;
    price_for_five_days: string | null;
    price_for_six_days: string | null;
    price_for_seveen_days: string | null;
    weekly_price_for_one_day: string | null;
    weekly_price_for_two_days: string | null;
    weekly_price_for_three_days: string | null;
    weekly_price_for_four_days: string | null;
    weekly_price_for_five_days: string | null;
    weekly_price_for_six_days: string | null;
    weekly_price_for_seveen_days: string | null;
  }
}

export interface DayCareServiceCosts {
  day_care_services: {
    data: Array<DayCareService>
  }
}

export interface PetData {
  id: string;
  attributes: {
    name: string;
    pet_type: string;
    pet_photo: string;
  }
}
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  catRooms: string,
  dogRooms: string,
  selectRange: any,
  dailogOpen: boolean;
  selectedFor: string;
  selectRoom: string;
  selectRoomPet: string;
  selectRoomForDog: string;
  selectRoomForDogPrice: number;
  selectRoomForCatPrice: number;
  selectRoomForCat: string;
  hotelId: string;
  roomDetailId: null | string;
  roomDetailData: any[];
  hotelDetails: HotelDetails;
  petDetailsPageLoader: boolean;
  selectedType: string;
  propertyDetailsModal: boolean;
  petType: string;
  dogHoteldata: any[];
  checkInOut: boolean;
  inOutAnchorElPet: HTMLDivElement | null;
  inputRange: string;
  selectedRangeDate: DateObject[];
  addRoomCat: number;
  addRoomDog: number;
  dialogOpen: boolean;
  anchorElPet: HTMLButtonElement | null | HTMLDivElement;
  additionalServicesPet: any[];
  servicesDog: any[];
  servicesDog1: any[];
  roomsCat: any[];
  roomsDog: any[];
  checkedServicesDog: any[];
  additionalServe: string;
  petTypeAdditionalServe: string;
  roomTypeForDog: any[];
  roomTypeForCat: any[];
  checkedServicesCat: any[];
  checkedServices: any[];
  selectRoomForPetClose: boolean;
  addAdditionalServiceForPetClose: boolean;
  checkInDate: any;
  checkOutDate: any;
  totalDay: number;
  checkInDateError: string;
  checkOutDateError: string;
  checkedServicesDogPrice: any[];
  checkedServicesCatPrice: any[];
  totalPrice: number;
  hotel_addtionl_service_id: number | null;
  additional_service_id: number | null;
  room_id: number | null;
  openSnck: boolean,
  orderCreationId: null | number;
  accountId?: string | number;
  reviewStatus: string | boolean | null;
  hotelType: string;
  flashLogin: boolean;
  validToken: any;
  dogRoomId: string;
  catRoomId: string;
  selectedRoomDogCount: number;
  selectedRoomCatCount: number;
  conditionSlack: boolean;
  slackData: string;
  coords: [number, number];
  oneMonthStay: boolean;
  isOpenDayCareDialog: boolean;
  userPetList: Array<PetData>;
  priceList: DayCareService;
  selectedDays: number[];
  petId: string[];
  selectedPricingType: string;
  openPetPopup: boolean;
  additionalDetails: string;
  displayPrice: string;
  modalError: string;
  petName: string[];
  catRoomIndex: number;
  dogRoomIndex: number;
  dogSelectedHotel: Array<SelectedHotel>;
  catSelectedHotel: Array<SelectedHotel>;
  isDogRoomSelected: boolean;
  isCatRoomSelected: boolean;
  dayCareDogCount: number;
  dayCareCatCount: number;
  multipleRoomError: string;
  socialLinksData: { icons: string, navigationUrl: string }[];
  poBOLinksData: LinkObject[];
  checkAvailability: {
    isAvailable: boolean,
    availabilityMessage: string,
  },
  checkDate: {
    isAvailable: boolean,
    availabilityMessage: string,
  },
  numberOfWeeks: number;
  oneMonthStayToast: boolean,
  oneMonthStayMessage: string,
  roomErrorDogMessage: string,
  roomErrorCatMessage: string,
  availableRooms: { roomId: string, available: number, roomName: string }[];
  availabilityError: boolean;
  availableSpace: { [key: string]: number }[];
  dayCareFormSubmited: boolean;
  dayCareDays: number;
  dateErrorText: string;
  isSubmitted: boolean;
  tokenStatus: boolean;
  orderId?: string,
  dateErrorMessage:string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PetDetailsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdSeeHotelDetails: any;
  addPetCallPoId: string = "";
  orderCreationId: string = "";
  userAllPetsListApiCallId: string = "";
  userDayCareApiCallId: string = "";
  apiCallIdCheckAvailability: string = "";
  hotelRoomsCallId: string = "";
  getAvailableSpaceCallId: string = "";
  apiCallIdHotelReviews: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      catRooms: "",
      roomDetailData: [],
      roomDetailId: null,
      dialogOpen: false,
      dogRooms: "",
      selectRange: [],
      dailogOpen: false,
      selectedFor: "",
      selectRoom: "",
      selectRoomPet: "",
      selectRoomForDog: "",
      selectRoomForDogPrice: 0,
      selectRoomForCat: "",
      selectRoomForCatPrice: 0,
      hotelId: "",
      hotelDetails: {
        data: {
          id: "",
          type: "",
          attributes: {
            day_care_services: {
              data: [
                {
                  attributes: {
                    price_for_one_day: ""
                  }
                }
              ]
            },
            check_in_check_out_times_present: false,
            id: 0,
            name: "",
            address: "",
            account_id: 0,
            check_in: "",
            check_out: "",
            hotel_type: "",
            room_type: "",
            phone_number: 0,
            latitude: undefined,
            longitude: undefined,
            property_name: "",
            license_number: "",
            country_region: "",
            flat_suite: "",
            postcode: "",
            email: "",
            daycare_price: "",
            title: "",
            budget_per_day: undefined,
            rating: undefined,
            service_type: "",
            additional_services: [],
            images: [],
            documents: [],
            reviews_count: 0,
            address_line_2: "",
            country: "",
            surname: "",
            first_name: "",
            owner_details: undefined,
            representative_details: [],
            ["Types of rooms"]: {} as RoomDetails[],
            hotel_address_line_1: "",
            hotel_address_line_2: "",
            hotel_city: "",
            hotel_country: "",
            hotel_postcode: undefined,
            hotel_country_region: "",
            check_in_start: "",
            check_in_end: "",
            check_out_start: "",
            check_out_end: ""
          }
        }
      },
      petDetailsPageLoader: false,
      selectedType: "",
      roomTypeForDog: [],
      roomTypeForCat: [],
      propertyDetailsModal: false,
      addRoomCat: 0,
      addRoomDog: 0,
      inputRange: "",
      selectedRangeDate: [],
      anchorElPet: null,
      inOutAnchorElPet: null,
      checkInOut: false,
      petType: "",
      dogHoteldata: [{ description: "The room price of one dog per day", price: "£20" },
      { description: "The room price of one dog per day", price: "£10" },
      { description: "The room price of two dogs per day", price: "£30" },
      { description: "The room price of two dogs per day", price: "£40" },
      { description: "The room price of one dog per day", price: "£50" },
      { description: "The room price of one dog per day", price: "£60" },
      { description: "The room price of one dog per day", price: "£70" },
      { description: "The room price of one dog per day", price: "£80" },
      { description: "The room price of two dogs per day", price: "£90" },
      { description: "The room price of two dogs per day", price: "£100" },],
      additionalServicesPet: [],
      servicesDog: [],
      servicesDog1: [],
      roomsCat: [],
      roomsDog: [],
      checkedServicesDog: [],
      checkedServicesCat: [],
      additionalServe: "",
      checkedServices: [],
      petTypeAdditionalServe: "",
      selectRoomForPetClose: false,
      addAdditionalServiceForPetClose: false,
      checkInDate: "",
      checkOutDate: "",
      totalDay: 0,
      checkInDateError: "",
      checkOutDateError: "",
      checkedServicesDogPrice: [],
      checkedServicesCatPrice: [],
      totalPrice: 0,
      hotel_addtionl_service_id: null,
      additional_service_id: null,
      room_id: null,
      openSnck: false,
      orderCreationId: null,
      accountId: "",
      reviewStatus: "",
      hotelType: "",
      flashLogin: false,
      validToken: "",
      dogRoomId: "",
      catRoomId: "",
      selectedRoomDogCount: 0,
      selectedRoomCatCount: 0,
      conditionSlack: false,
      slackData: "",
      coords: [20.5937, 78.9629],
      oneMonthStay: false,
      catRoomIndex: -1,
      dogRoomIndex: -1,
      isDogRoomSelected: true,
      isCatRoomSelected: true,
      isOpenDayCareDialog: false,
      userPetList: [],
      selectedDays: [],
      petId: [],
      selectedPricingType: "OneDay",
      openPetPopup: false,
      additionalDetails: "",
      displayPrice: "0",
      modalError: "",
      priceList: {
        id: 0,
        type: "",
        attributes: {
          spaces: null,
          price_for_one_day: null,
          price_for_two_days: null,
          price_for_three_days: null,
          price_for_four_days: null,
          price_for_five_days: null,
          price_for_six_days: null,
          price_for_seveen_days: null,
          weekly_price_for_one_day: null,
          weekly_price_for_two_days: null,
          weekly_price_for_three_days: null,
          weekly_price_for_four_days: null,
          weekly_price_for_five_days: null,
          weekly_price_for_six_days: null,
          weekly_price_for_seveen_days: null
        }
      },
      petName: [],
      dogSelectedHotel: [{
        roomId: 0,
        name: "",
        bookings: 0,
        price: "",
      }],
      catSelectedHotel: [{
        roomId: 0,
        name: "",
        bookings: 0,
        price: "",
      }],
      dayCareDogCount: 0,
      dayCareCatCount: 0,
      multipleRoomError: "",
      socialLinksData: [],
      poBOLinksData: [],
      numberOfWeeks: 1,
      checkAvailability: {
        isAvailable: false,
        availabilityMessage: '',
      },
      checkDate: {
        isAvailable: false,
        availabilityMessage: '',
      },
      oneMonthStayToast: false,
      oneMonthStayMessage: '',
      roomErrorDogMessage: "",
      roomErrorCatMessage: "",
      availableRooms: [],
      availabilityError: false,
      availableSpace: [],
      dayCareFormSubmited: false,
      dayCareDays: 0,
      dateErrorText: "",
      isSubmitted: false,
      tokenStatus: false,
      orderId: "",
  dateErrorMessage:""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getUserPets();

    window.scrollTo(0, 0);

    this.validateReviewPopup()
    const selectHotelID = await getStorageData('selectedHotelId', true)
    if (selectHotelID) {
      this.seeHotelDetails(selectHotelID)
      this.getHotelRooms(selectHotelID);
    }
    // pagecomingFrom

    const searchbardata = await getStorageData('serchBarData', true)
    const pagecomingFrom = await getStorageData('pagecomingFrom')
    if (pagecomingFrom != "landingPage") {
      if (searchbardata) {
        this.setState({
          addRoomCat: searchbardata.addRoomCat,
          addRoomDog: searchbardata.addRoomDog,
          checkInDate: new Date(searchbardata.checkIn) || new Date(),
          checkOutDate: new Date(searchbardata.checkOut) || new Date(),
          checkInDateError: (searchbardata.checkIn) || "",
          checkOutDateError: (searchbardata.checkOut) || ""
        })
      }

    }

    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({ socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks", true)
    this.setState({ poBOLinksData: allLinksData })
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      this.handleNavigationPayload(message);
    } else {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      switch (apiRequestCallId) {
        case this.apiCallIdSeeHotelDetails:

          this.handleSeeHotelDetailsApiResponse(responseJson);
          break;
        case this.addPetCallPoId:
          this.handleAddPetApiResponse(responseJson);
          break;
        case this.orderCreationId:
          this.handleOrderCreationApiResponse(responseJson);
          break;
        case this.userAllPetsListApiCallId:
          this.userPetListApiResponce(responseJson);
          break;
        case this.userDayCareApiCallId:
          this.dayCareResponce(responseJson);
          break;
        case this.apiCallIdCheckAvailability:
          this.checkAvailabilityResponse(responseJson);
          break;
        case this.hotelRoomsCallId:
          this.checkActiveRooms(responseJson);
          break;
        case this.getAvailableSpaceCallId:
          this.checkAvailableSpaces(responseJson);
          break;
        case this.apiCallIdHotelReviews:
          this.handleReviewProvideApiResponce(responseJson)
          break;

      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  validateReviewPopup = () => {
    let url = new URL(window.location.href);
    const prams = new URLSearchParams(url.search);
    if (prams.get('account_id') && prams.get('hotel_id') && prams.get('order_id')) {
      let order_id = prams.get('order_id') || ""
      let hotel_id = prams.get('hotel_id') || ""
      this.getHotelReviewsStatus(order_id)
      this.setState({
        accountId: prams.get('account_id') ?? "",
        hotelId: hotel_id,
        orderId: order_id
      }, () => {
        this.seeHotelDetails(this.state?.hotelId);
      })
    }
  }

  toasterClose = () => {
    this.setState({ availabilityError: false })
  }


  async componentDidUpdate(prevProps: any, prevState: any) {

    if (
      this.state.checkInDate !== prevState.checkInDate ||
      this.state.checkOutDate !== prevState.checkOutDate
    ) {
      const daysDifference = this.calculateDateDifference();
      this.setState({
        totalDay: Math.ceil(Number(this.state.checkOutDate) - Number(this.state.checkInDate)) / 86400000,
        dogSelectedHotel: [{
          roomId: 0,
          name: "",
          bookings: 0,
          price: "",
        }],
        catSelectedHotel: [{
          roomId: 0,
          name: "",
          bookings: 0,
          price: "",
        }],
        checkedServicesCatPrice: [],
        checkedServicesCat: [],
        checkedServices: [],
      });
      this.clearPreviousData()
    }

    if (
      prevState.checkedServicesDog !== this.state.checkedServicesDog ||
      prevState.checkedServicesCat !== this.state.checkedServicesCat ||
      prevState.selectRoomForDogPrice !== this.state.selectRoomForDogPrice ||
      prevState.selectRoomForCatPrice !== this.state.selectRoomForCatPrice ||
      prevState.addRoomDog !== this.state.addRoomDog ||
      prevState.addRoomCat !== this.state.addRoomCat ||
      prevState.totalDay !== this.state.totalDay

    ) {
      this.calculateDateDifference();
      this.calculateTotalPrice();
    }
  }

  handleNavigationPayload(message: Message) {
    const selectedDetails = message.getData(getName(MessageEnum.HotelDetails));
    this.setState({
      catRooms: selectedDetails.cats,
      dogRooms: selectedDetails.dogs,
      selectRange: selectedDetails.selectedRange,
    });
  }

  filterAvailableRooms(responseJson: { id: string, attributes: { room_for: string, room_type: string, status: string, available: string | number } }[], roomType: string) {
    const room = [...responseJson];
    return room.filter((value) => value.attributes.room_for == roomType && value.attributes.status === "active" && Number(value.attributes.available) > 0);
  };

  checkAvailableSpaces = (responseJson: { data: { attributes: { day_care_services: { data: { attributes: { available_spaces: { ["date"]: number }[]; }; }[]; }; }; }; }) => {
    const spaces = responseJson.data?.attributes?.day_care_services?.data[0]?.attributes?.available_spaces;
    this.setState({
      availableSpace: spaces
    });
  };

  handleReviewProvideApiResponce = (responseJson: { reviews: boolean }) => {
    if (responseJson.reviews) {
      this.setState({
        reviewStatus: false, availabilityError: true,
        multipleRoomError: "You have already provided the review"
      }, () => {
        setTimeout(() => {
          handleNavigation('LandingPage', {}, this.props, this.send)

        }, 3000)

      })
    } else {
      this.setState({
        reviewStatus: true,

      })
    }

  }

  handleAddPetApiResponse = async (responseJson: any) => {
    const roomOfHotel = [...responseJson.rooms.data];
    const roomsData: { roomId: string, available: number, roomName: string }[] = [];
    roomOfHotel.forEach((rooms) => {
      roomsData.push({
        roomId: rooms.id,
        available: rooms.attributes.available,
        roomName: rooms.attributes.room_type
      })
    });
    this.setState({ availableRooms: roomsData })


    if (responseJson && !responseJson.error) {
      const dogRooms = this.filterAvailableRooms(roomOfHotel, "Dog");
      const catRooms = this.filterAvailableRooms(roomOfHotel, "Cat");

      this.setState({ selectRoomForDog: dogRooms[0]?.attributes?.room_type, dogRoomId: dogRooms[0]?.id });
      this.setState({ selectRoomForCat: catRooms[0]?.attributes?.room_type, catRoomId: catRooms[0]?.id });



      if (dogRooms.length > 0) {
        this.handleRoomDetailsUpdate(dogRooms[0], dogRooms, "roomsDog", "roomTypeForDog");
      } else {
        this.setState({
          roomDetailId: null,
          roomDetailData: [],
          selectRoom: "",
          roomsDog: [],
          roomTypeForDog: []
        })
      }
      if (catRooms.length > 0) {
        this.handleRoomDetailsUpdate(catRooms[0], catRooms, "roomsCat", "roomTypeForCat");
      } else {
        this.setState({
          roomDetailId: null,
          roomDetailData: [],
          selectRoom: "",
          roomsCat: [],
          roomTypeForCat: []
        })
      }
    }
  }

  filterRooms(responseJson: any, roomType: string) {
    const room = [...responseJson]
    return room.filter((value) => value.room_for == roomType && value.status === "active");
  }

  handleRoomDetailsUpdate(room: any, roomsArray: any[], roomsStateKey: string, roomTypeStateKey: string) {



    const stateUpdates: any = {
      roomDetailId: room.id,
      roomDetailData: room,
      selectRoom: room.attributes.room_type,
      // selectRoomForDog: "Select Room",
      // selectRoomForCat: "Select Room",
      [roomsStateKey]: roomsArray.reduce((accumulator: any[], value: any) => {
        if (!accumulator.some((room) => room.name === value.attributes.room_type)) {
          accumulator.push({
            name: value.attributes.room_type,
            data: value,
          });
        }
        return accumulator;
      }, []),
      [roomTypeStateKey]: [...new Set(roomsArray.map((value: any) => value.attributes.room_type))],
    };

    this.setState(stateUpdates);

    sessionStorage.setItem("roomDetailId", room.id);
  }

  handleSeeHotelDetailsApiResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      const services = []
      const service1 = responseJson.data?.attributes?.hotel_additional_services
      const service2 = responseJson.data?.attributes?.['Create your own services']
      services.push(...service1, ...service2);
      this.setState({
        hotelDetails: responseJson,
        petDetailsPageLoader: false,
        servicesDog: services,
        servicesDog1: responseJson.data?.attributes?.['Create your own services'],
        hotelType: responseJson.data?.attributes?.pet_type,
        hotel_addtionl_service_id: responseJson.data?.attributes?.hotel_additional_services[0]?.id,
        additional_service_id: responseJson.data?.attributes?.hotel_additional_services[0]?.additional_service_id,
        coords: [responseJson.data?.attributes?.latitude, responseJson.data?.attributes?.longitude],
        oneMonthStay: responseJson.data?.attributes?.more_than_30_days
      }, () => {
        if (responseJson.data?.attributes?.pet_type === "daycare_services") {
          this.getDayCareServiceCost();
        }
      }
      );
    }
  }

  handleAddToCartApiResponse(responseJson: any) {
    sessionStorage.setItem("addTOCartId", responseJson.data.id);
  }

  handleOrderCreationApiResponse(responseJson: any) {
    this.setState({
      orderCreationId: responseJson.data.id,
    });


  }



  OnShoppingCart = async () => {

    this.setState({ validToken: await getStorageData("authToken") })
    let additionalServiceList = this.state.checkedServicesCatPrice;
    let ownServiceList = this.state.checkedServicesCatPrice;

    ownServiceList = ownServiceList.filter(item1 =>
      this.state.servicesDog1.some(item2 => item1.hotel_additional_service_id === item2.id)
    );
    additionalServiceList = additionalServiceList.filter(item1 =>
      !this.state.servicesDog1.some(item2 => item1.hotel_additional_service_id === item2.id)
    );

    const order_creation = {

      hotel_id: this.state.hotelDetails.data?.id,

      room_id: this.state.roomDetailId,
      hotelType: this.state.hotelType,
      check_in_date: this.state.checkInDateError,
      check_out_date: this.state.checkOutDateError,
      number_of_dogs: this.state.addRoomDog,
      number_of_cats: this.state.addRoomCat,
      price: this.state.totalPrice,
      additionalDetails: this.state.additionalDetails,
      days: this.state.selectedDays,
      pricetype: this.state.selectedPricingType,
      petIds: this.state.petId,
      dayCareServicePrice: Number(this.state.displayPrice),
      for_cat: {
        type: this.state.selectRoomForCat,
        price: Number(this.state.selectRoomForCatPrice),
        number: this.state.selectedRoomCatCount,
        rooms: this.state.catSelectedHotel,
        additional_services: (
          additionalServiceList || []
        ),
        ownServices: ownServiceList || []
      },
      for_dog: {
        type: this.state.selectRoomForDog,
        price: Number(this.state.selectRoomForDogPrice),
        number: this.state.selectedRoomDogCount,
        rooms: this.state.dogSelectedHotel,
        additional_services: (
          this.state.checkedServicesDogPrice || []
        )
      },
      catRoomId: this.state.selectRoomForCatPrice > 0 ? this.state.catRoomId : "",
      dogRoomId: this.state.selectRoomForDogPrice > 0 ? this.state.dogRoomId : "",
      displayPrice: this.getSelectFeildText(),
      selectFeildText: this.getSelectFeildText(),
      petName: this.state.petName,
      numberOfWeeks: this.state.numberOfWeeks
    }
    setStorageData('order_creation', JSON.stringify(order_creation))
    setStorageData('price_list', JSON.stringify(this.state.priceList));
    removeStorageData('serchBarData')
    removeStorageData('fromReservation')

    if (this.state.validToken) {



      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'ShoppingCartOrders');
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);


      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), { fromPetDetails: true });
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

      this.send(message);

    } else {
      this.setState({ flashLogin: true });
      setTimeout(() => {
        this.onLogin()
      }, 5000);
    }
  }

  onLogin = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'LogInPetOwner');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);


    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { fromreservation: true });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }

  getRoomData = async (petType?: string) => {
    const selectHotelID = await getStorageData('selectedHotelId', true)


    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addPetCallPoId = loginRequestMessage.messageId;
    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.availabilityRoomsEndPoint}=${selectHotelID}&pet_type=${petType}&check_in_date=${this.state.checkInDateError}&check_out_date=${this.state.checkOutDateError}`

    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
  }

  calculateDateDifference(): number | undefined {
    const { checkInDate, checkOutDate } = this.state;

    if (!(checkInDate instanceof Date) || !(checkOutDate instanceof Date)) {
      return undefined;
    }

    const timeDifference = checkOutDate.getTime() - checkInDate.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  }


  handleCheckInDateChange = (date: DateObject) => {
    const { checkOutDate } = this.state
    const dateAsDate = date.toDate()
    const totalDay = Math.ceil((Number(checkOutDate) - Number(dateAsDate)) / 86400000)
    const formattedDateString = date.format("YYYY-MM-DD")
    this.setState({ checkInDate: dateAsDate, checkInDateError: formattedDateString, openSnck: false, totalDay: totalDay }, () => {
      this.validatecheckInCheckOutDateError()
      this.checkError()
      const startingDay = this.state.checkInDate.getDay();
      const selectedDays: number = Math.floor(this.state.totalDay);
      const listOfdays = [0, 0, 0, 0, 0, 0, 0];
      let date1 = 0, getDay = startingDay;
      while (date1 < selectedDays) {
        listOfdays[getDay] += 1;
        getDay = (getDay + 1) % 7;
        date1 += 1;
      }
      if(listOfdays.every((value: number) => value === listOfdays[0])) {
        this.setState({ modalError: "" })
      }
      else {
        if(this.state.selectedPricingType === "Weekly") {
          this.setState({ modalError: "These days do not match the criteria." })
        } else if(this.state.selectedPricingType === "OneDay") {
          this.setState({ modalError: "" })
        }
      }
    });
  };
    
  handleCheckOutDateChange = (date: DateObject) => {
    const { checkInDate } = this.state
    const dateAsDate = date.toDate();
    const totalDay = Math.ceil((Number(dateAsDate) - Number(checkInDate)) / 86400000)
    const formattedDateString = date.format("YYYY-MM-DD");
    this.setState({ checkOutDate: dateAsDate, checkOutDateError: formattedDateString, openSnck: false, totalDay: totalDay }, () => {
      this.validatecheckInCheckOutDateError()
      this.checkError()

      const numberOfSelectedDays: number = Math.floor(this.state.totalDay);
      const dayOfStart = this.state.checkInDate.getDay();
      
      let dateIterate = 0, getday = dayOfStart;
      const listsDays = [0, 0, 0, 0, 0, 0, 0];
      while (dateIterate < numberOfSelectedDays) {
        listsDays[getday] += 1;
        getday = (getday + 1) % 7;
        dateIterate += 1;
      }
      if(listsDays.every((value: number) => value === listsDays[0])) {
        this.setState({ modalError: "" })
      }
      else {
        if(this.state.selectedPricingType === "Weekly") {
          this.setState({ modalError: "These days do not match the criteria." })
        } else if(this.state.selectedPricingType === "OneDay") {
          this.setState({ modalError: "" })
        }
      }
    })
  };


  validatecheckInCheckOutDateError=()=>{
      const checkInDate = new Date(this.state.checkInDate);
      const checkOutDate = new Date(this.state.checkOutDate);
      if (checkInDate && checkOutDate) {
      if (checkOutDate <= checkInDate) {
        this.setState({dateErrorMessage:configJSON.dateErrorMessage});
      }else{
        this.setState({dateErrorMessage:""});

      }
    }
  
  }



  openAddPetModel = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      anchorElPet: event.currentTarget
    })
  }


  addPetsPopupClose = () => {
    this.setState({
      anchorElPet: null,
    })
  }

  addPetsPopupCloseSave = () => {
    this.setState({
      anchorElPet: null
    })
  }

  handlePropertyModalOpen = () => {
    this.setState({
      propertyDetailsModal: true
    })

  }
  handlePropertyModalClose = () => {
    this.setState({
      propertyDetailsModal: false
    })

  }

  handleOpenDialog = (selectingType: string, roomIndex: number) => {
    window.scrollTo({
      top: 0,
    });
    if (selectingType === "dogs") {
      this.setState({
        dailogOpen: this.state.selectRoomForDog !== "No room",
        selectedFor: selectingType,
        selectedType: selectingType,
        selectRoomPet: this.state.selectRoomForDog,
        dogRoomIndex: roomIndex
      }, () => {
        this.getRoomData('Dog')
      });
    } else if (selectingType === "cats") {
      this.setState({
        dailogOpen: this.state.selectRoomForCat !== "No room",
        selectedFor: selectingType,
        selectedType: selectingType,
        selectRoomPet: this.state.selectRoomForCat,
        catRoomIndex: roomIndex
      }, () => {
        this.getRoomData('Cat')
      });
    } else {
      this.setState({
        dailogOpen: false,
      });
    }
  };
  handleAvailabilityRoomsPopup = (selectingType: string, roomIndex: number) => {
    const { checkInDateError, checkOutDateError, roomErrorDogMessage, roomErrorCatMessage } = this.state
    if (!checkInDateError && !checkOutDateError) {
      this.setState({
        checkAvailability: {
          isAvailable: true,
          availabilityMessage: "Please Select The Check in And Check out Dates"
        }
      })
    } else {
      this.setState({
        checkAvailability: {
          isAvailable: false,
          availabilityMessage: ""
        }
      }, () => {
        if ((roomErrorDogMessage === "" && selectingType === "dogs") || (roomErrorCatMessage === "" && selectingType === "cats")) {
          this.handleOpenDialog(selectingType, roomIndex);
        }
      })
    }

  }
  selectRoomForPet = () => {
    this.setState({
      selectRoomForPetClose: true,
      propertyDetailsModal: false,
      dailogOpen: false,
      selectRoomPet: "",
      selectRoomForDogPrice: 0,
      selectRoomForCatPrice: 0
    })
  }
  handleOpenServices = (selectdType: string) => {
    this.setState({
      selectedType: selectdType
    })

  }

  handleCloseDailog = () => {
    this.setState({
      dailogOpen: false,
      selectedType: ""

    })
  }

  handleChangeDate = (values: DateObject[]) => {
    const startDate = values[0]?.format("DD MMM") || "";
    const endDate = values[1]?.format("DD MMM") || "";
    const inputRange = `${startDate} - ${endDate}`;
    var date1 = new Date(startDate);
    var date2 = new Date(endDate);
    this.setState({ selectedRangeDate: values, inputRange });
  };
  minusdogsRoomOnClick = () => {
    if (this.state.addRoomDog > 1) {
      this.setState({ addRoomDog: this.state.addRoomDog - 1 },
        () => {
          this.checkMoreRoomNeeded()
          if (this.state.addRoomDog === 0) {
            this.setState({
              dogSelectedHotel: [{
                roomId: 0,
                name: "",
                bookings: 0,
                price: ""
              }
              ]
            },
              () => { this.calculateTotalPrice(); }
            )
          }
        });
    } else if (this.state.addRoomDog === 1) {
      this.setState({ addRoomDog: this.state.addRoomDog - 1 },
        () => {
          this.checkMoreRoomNeeded()
          if (this.state.addRoomDog === 0) {
            this.setState({
              dogSelectedHotel: [{
                roomId: 0,
                name: "",
                bookings: 0,
                price: ""
              }
              ]
            },
              () => { this.calculateTotalPrice(); }
            )
          }
        });
      this.setState({
        checkedServicesCatPrice: [],
        checkedServicesCat: [],
        checkedServices: []
      });
    }
  };
  adddogsRoomOnClick = () => {
    if (this.state.addRoomDog < 30) {
    this.setState({ addRoomDog: this.state.addRoomDog + 1 }, () => this.checkMoreRoomNeeded());
    }
  };
  handleDecrement = () => {
    if (this.state.addRoomCat > 1) {
      this.setState(prevState => ({
        addRoomCat: prevState.addRoomCat - 1
      }), () => {
        this.checkMoreRoomNeeded()
        if (this.state.addRoomCat === 0) {
          this.setState({
            catSelectedHotel: [{
              roomId: 0,
              name: "",
              bookings: 0,
              price: ""
            }
            ]
          },
            () => { this.calculateTotalPrice(); }
          )
        }
      });
    } else if (this.state.addRoomCat === 1) {
      this.setState(prevState => ({
        addRoomCat: prevState.addRoomCat - 1
      }), () => {
        this.checkMoreRoomNeeded()
        if (this.state.addRoomCat === 0) {
          this.setState({
            catSelectedHotel: [{
              roomId: 0,
              name: "",
              bookings: 0,
              price: ""
            }
            ]
          },
            () => { this.calculateTotalPrice(); }
          )
        }
      });
      this.setState({
        checkedServicesCatPrice: [],
        checkedServicesCat: [],
        checkedServices: []
      })
    }
  };
  handleIncrement = () => {
    if(this.state.addRoomCat<30){
    this.setState(prevState => ({
      addRoomCat: prevState.addRoomCat + 1
    }), () => this.checkMoreRoomNeeded());
  }
  };
  handlePetTypeChange = (value: string, selectedRoomFor: string, price: any, id: any) => {

    if (selectedRoomFor === "dogs") {
      const priceArray = Array.isArray(price) ? price.map((value: string) => parseFloat(value)) : [];

      const sumOfPrices = priceArray.reduce((acc: number, currentValue: number) => acc + currentValue, 0);

      this.setState({
        selectRoomPet: value,
        selectRoomForDog: value,
        // selectRoomForDogPrice: price,
        dogRoomId: id,

      });





    } else if (selectedRoomFor === "cats") {
      const priceArray = Array.isArray(price) ? price.map((value: string) => parseFloat(value)) : [];

      const sumOfPrices = priceArray.reduce((acc: number, currentValue: number) => acc + currentValue, 0);

      this.setState({
        selectRoomPet: value,
        selectRoomForCat: value,
        // selectRoomForCatPrice: price,
        catRoomId: id
      });



    }
  }


  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, additionalServe: string, prices: number, addtinalId: number, id: number, isDailyService: boolean, isAll: boolean) => {
    event.persist();
    const { value } = event.target;
    let selectedServiceOnChange = value;
    const perDayPrice = prices;

    if (event.target.checked) {
      let newState = {};

      if (additionalServe === "Additional Services for Dogs") {
        const newDogService = { name: `${selectedServiceOnChange}`, price: prices, additional_service_id: id, hotel_additional_service_id: id, isDailyService: isDailyService, perDayPrice: perDayPrice };

        if (!this.state.checkedServicesDogPrice.some(service => service.name === newDogService.name)) {
          newState = {
            checkedServicesDog: [...new Set([...this.state.checkedServicesDog, selectedServiceOnChange])],
            checkedServicesDogPrice: [...this.state.checkedServicesDogPrice, newDogService],
          };
        }
      } else if (additionalServe === "Additional Services for Cats") {
        const newCatService = { name: `${selectedServiceOnChange}`, price: prices, additional_service_id: id, hotel_additional_service_id: id, isDailyService: isDailyService, perDayPrice: perDayPrice, isAllPets: isAll };

        if (!this.state.checkedServicesCatPrice.some(service => service.name === newCatService.name)) {
          newState = {
            checkedServicesCat: [...this.state.checkedServicesCat, selectedServiceOnChange],
            checkedServicesCatPrice: [...this.state.checkedServicesCatPrice, newCatService],
          };
        }
      }

      this.setState((prevState) => ({
        ...newState,
        checkedServices: [...new Set([...prevState.checkedServices, selectedServiceOnChange])],
        addAdditionalServiceForPetClose: false,
      }));
    } else {
      this.setState((prevState) => ({
        checkedServicesDog: prevState.checkedServicesDog.filter(
          (service) => service !== selectedServiceOnChange
        ),
        checkedServicesCat: prevState.checkedServicesCat.filter(
          (service) => service !== selectedServiceOnChange
        ),
        checkedServices: prevState.checkedServices.filter(
          (service) => service !== selectedServiceOnChange
        ),
        checkedServicesDogPrice: prevState.checkedServicesDogPrice.filter(
          (service) => service.name !== `${selectedServiceOnChange}`
        ),
        checkedServicesCatPrice: prevState.checkedServicesCatPrice.filter(
          (service) => service.name !== `${selectedServiceOnChange}`
        ),
      }));
    }
  };



  calculateTotalPrice = () => {
    const {
      checkedServicesDogPrice,
      checkedServicesCatPrice,
      selectRoomForDogPrice,
      selectRoomForCatPrice,
      addRoomDog,
      addRoomCat,
      totalDay,
      dogSelectedHotel,
      catSelectedHotel
    } = this.state;
    const totalDayStay = this.state.hotelType === 'daycare_services' ? this.state.dayCareDays : Math.floor(Number(totalDay)) || 0;
    
    const calculateServiceTotal = (services: any[], petCount: number) => {
      return services
      .filter(service => !isNaN(service.price))
      .reduce((total, service) => {
        if (service.isDailyService && !service.isAllPets||service.isDailyService && service.isAllPets) {
          return total + Number(service.price * totalDayStay)
        } else {
          return total + Number(service.price * 1)
          
        }
      }, 0);
    };
    
    
    const totalDogServicesPrice = calculateServiceTotal(checkedServicesDogPrice, addRoomDog);
    const totalCatServicesPrice = calculateServiceTotal(checkedServicesCatPrice, addRoomCat + addRoomDog);
    const totalDayCareServicePrice = calculateServiceTotal(checkedServicesCatPrice, addRoomCat + addRoomDog);
    
    let dogRoomPrice = this.state.dogSelectedHotel.reduce((sumofRooms, selectedRoom) => Number(sumofRooms) + Number(selectedRoom.price), 0);
    let catRoomPrice = this.state.catSelectedHotel.reduce((sumofRooms, selectedRoom) => Number(sumofRooms) + Number(selectedRoom.price), 0);
    
    const totalPriceRoom =
    totalCatServicesPrice +
    (dogSelectedHotel.length > 0 ? dogRoomPrice * totalDayStay : 0) +
    (catSelectedHotel.length > 0 ? catRoomPrice * totalDayStay : 0);
    
    const totalPriceDayCare = (Number(this.state.displayPrice) * this.state.petId.length * this.state.numberOfWeeks) + totalDayCareServicePrice;
    
    const totalPrice = this.state.hotelType === "daycare_services" ? totalPriceDayCare.toFixed(2) : totalPriceRoom.toFixed(2);

    this.setState({ totalPrice });
  };


  addAdditionalService = () => {
    this.setState({
      addAdditionalServiceForPetClose: true,
      propertyDetailsModal: false,
      dailogOpen: false,
    })
  }

  handleAdditinalAddPet = (value: string) => {
    window.scrollTo({
      top: 0
    });
    this.setState({
      petType: value
    });
    if (value === "dogAdditional") {
      this.setState({
        dailogOpen: true,
        additionalServicesPet: [...this.state.servicesDog],
        additionalServe: "Additional Services for Dogs",
        selectedType: value,
      })
    } else if (value === "catAdditional") {
      this.setState({
        dailogOpen: true,
        additionalServicesPet: [...this.state.servicesDog],
        additionalServe: "Additional Services for Cats",
        selectedType: value
      })
    }
  };

  checkRoomAvailability = () => {
    const assignedRooms = [...this.state.dogSelectedHotel, ...this.state.catSelectedHotel].filter(rooms => rooms.roomId !== 0);
    const availableRooms = this.state.availableRooms;
    let errorMessage = "";
    const roomIdList = new Map();
    assignedRooms.forEach(function (assignedRoom) {
      if (roomIdList.has("" + assignedRoom.roomId)) {
        roomIdList.set("" + assignedRoom.roomId, roomIdList.get("" + assignedRoom.roomId) + 1);
      } else {
        roomIdList.set("" + assignedRoom.roomId, 1);
      }
    });
    const nonExistingRooms: { roomId: string, available: number, roomName: string }[] = []
    availableRooms.forEach(function (roomDetail) {
      if (roomIdList.has("" + roomDetail.roomId) && Number(roomDetail.available) < Number(roomIdList.get("" + roomDetail.roomId))) {
        nonExistingRooms.push({ roomId: roomDetail.roomId, available: roomDetail.available, roomName: roomDetail.roomName })
      }
    })
    if (nonExistingRooms.length > 0) {
      errorMessage = "There are only ";
      nonExistingRooms.forEach((room, roomIndex) => {
        if (roomIndex === 0) {
          errorMessage = errorMessage + `"${room.available}" ${room.available === 1 ? "room" : "rooms"} of type ${room.roomName}`;
        } else {
          errorMessage = errorMessage + `, "${room.available}" ${room.available === 1 ? "room" : "rooms"} of type ${room.roomName}`;
        }
      })
      errorMessage = errorMessage + " available at the moment";
    }
    return errorMessage;
  };

  checkConditionDayCare = () => {
    if (this.state.petId.length === 0) {
      this.setState({ conditionSlack: true, slackData: `Please select pets for daycare service` }
        , () => { this.setState({ conditionSlack: false }) }
      );
    } else if (this.state.selectedDays.length === 0) {
      this.setState({ conditionSlack: true, slackData: `Please select days for daycare service` }
        , () => { this.setState({ conditionSlack: false }) }
      );
    } else if (this.state.additionalDetails === "") {
      this.setState({ conditionSlack: true, slackData: `Please enter additional details` }
        , () => { this.setState({ conditionSlack: false }) }
      );
    } else {
      this.reserveRoom();
      this.setState({ conditionSlack: false })
    }
  }

  checkCondition = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    this.setState({
      isSubmitted: true
    }, () => {
      this.setState({
        isSubmitted: false
      });
    });
    if (this.checkInDateError()) {
      this.setState({
        checkDate: {
          isAvailable: true,
          availabilityMessage: "Please choose a valid date: the checkout date must be after the check-in date"
        }
      }, () => {
        setTimeout(() => {
          this.setState({
            checkDate: {
              isAvailable: false,
              availabilityMessage: "Please choose a valid date: the checkout date must be after the check-in date"
            }
          })
        }, 3000);
      })
    }
    else if (this.state.addRoomDog === 0 && this.state.addRoomCat === 0) {
      this.setState({ conditionSlack: true, slackData: `Please select atleast one pet.` }
        , () => { this.setState({ conditionSlack: false }) }
      )
    }
    else {
      if (this.state.hotelType !== "daycare_services") {
        let errorMsg = this.checkRoomAvailability();
        let dogRoom = this.state.dogSelectedHotel.reduce((sumofRooms, selectedRoom) => Number(sumofRooms) + Number(selectedRoom.bookings), 0);
        let catRoom = this.state.catSelectedHotel.reduce((sumofRooms, selectedRoom) => Number(sumofRooms) + Number(selectedRoom.bookings), 0);

        if (this.state.addRoomDog !== dogRoom && this.state.addRoomCat !== catRoom) {
          this.setState({ conditionSlack: true, slackData: `Please select the room for ${this.state.addRoomDog} Dogs and ${this.state.addRoomCat} Cats` }
            , () => { this.setState({ conditionSlack: false }) }
          )
        } else if (errorMsg !== "") {
          this.setState({ availabilityError: true, multipleRoomError: errorMsg }
            , () => { this.setState({ availabilityError: false }) }
          )
        } else if (this.state.addRoomDog !== dogRoom) {
          this.setState({ conditionSlack: true, slackData: `Please select the room for ${this.state.addRoomDog} Dogs` }
            , () => { this.setState({ conditionSlack: false }) }
          )
        } else if (this.state.addRoomCat !== catRoom) {
          this.setState({ conditionSlack: true, slackData: `Please select the room for ${this.state.addRoomCat} Cats` }
            , () => { this.setState({ conditionSlack: false }) }
          )
        } else {
          this.reserveRoom();
          this.setState({ conditionSlack: false })
        }
      } else {
        this.checkConditionDayCare();
      }
    }
  };

  checkInDateError = () => {
    const oneDayInMillis = 24 * 60 * 60 * 1000;
    const checkInDate = new Date(this.state.checkInDate);
    const checkOutDate = new Date(this.state.checkOutDate);

    if (checkOutDate <= checkInDate) {
      this.setState({ checkOutDate: checkOutDate, checkOutDateError: checkOutDate.toISOString().split('T')[0] });
    }

    const totalDay = Math.ceil(Number(checkOutDate) - Number(checkInDate)) / oneDayInMillis;

    if (!this.state.checkInDate || !this.state.checkOutDate || totalDay < 1) {
      return true;
    } else {
      return false;
    }
  };

  reserveRoom = () => {
    if (this.state.checkInDateError && this.state.checkOutDateError) {
      const oneDayInMillis = 24 * 60 * 60 * 1000;
      const checkInDate = new Date(this.state.checkInDate);
      const checkOutDate = new Date(this.state.checkOutDate);

      if (checkOutDate <= checkInDate) {
        checkOutDate.setDate(checkInDate.getDate() + 1);
        const formattedDateString = checkOutDate.toISOString().split('T')[0];
        this.setState({ checkOutDate: checkOutDate, checkOutDateError: formattedDateString });
      }

      const totalDay = Math.ceil(Number(checkOutDate) - Number(checkInDate)) / oneDayInMillis;




      if (totalDay < 1) {
        this.handelOpenSnack();
      } else if (!this.state.oneMonthStay && totalDay > 30) {
        this.setState({
          //toast will show , if more than 30 nights stay rooms not available
          oneMonthStayToast: true,
          //Disallowed booking more than 30 days
          oneMonthStayMessage: "This property do not allow booking for more than 30 days",
        }, () => { this.setState({ oneMonthStayToast: false }) })
      } else {
        this.OnShoppingCart();
      }
    }
    else {
      this.handelOpenSnack();
    }
  }



  handelOpenSnack = () => {
    this.setState({
      openSnck: true
    }, () => { this.setState({ openSnck: false }) })
  }

  handleCloseSnack = () => {
    this.setState({
      openSnck: false,
      flashLogin: false,
      conditionSlack: false
    })
  }


  orderCreationApi = () => {
    let selectedProfilePoId = sessionStorage.getItem("profileIdPo");
    let selectedhotelId = this.state.hotelDetails.data?.id
    let pet_Id = sessionStorage.getItem("petId")
    let token = localStorage.getItem("authToken")

    if (token) {
      const header = {
        "Content-Type": "application/json",
        token: token
      };

      const httpBody = {

        "order_creation": {
          "account_id": Number(selectedProfilePoId),
          "hotel_id": Number(selectedhotelId),
          "pet_id": Number(pet_Id),
          "room_id": this.state.roomDetailId,
          "additional_service_id": this.state.additional_service_id,
          "hotel_additional_service_id": this.state.hotel_addtionl_service_id,
          "check_in_date": this.state.checkInDateError,
          "check_out_date": this.state.checkOutDateError,
          "number_of_dogs": this.state.addRoomDog,
          "number_of_cats": this.state.addRoomCat,
          "price": this.state.totalPrice,
          "for_cat": {
            "type": this.state.selectRoomForCat,
            "price": this.state.selectRoomForCatPrice,
            "additional_services": (
              this.state.checkedServicesCatPrice?.map((value) => ({
                additional_service_id: value.additional_service_id,

                service_name: value.name,
                price: value.price
              })) || []
            )
          },
          "for_dog": {
            "type": this.state.selectRoomForDog,
            "price": this.state.selectRoomForDogPrice,
            "additional_services": (
              this.state.checkedServicesDogPrice?.map((value) => ({
                additional_service_id: value.additional_service_id,

                service_name: value.name,
                price: value.price
              })) || []
            )
          }
        }

      }



      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_shopping_cart/order_creations"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      this.orderCreationId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({ flashLogin: true })
    }


  }


  seeHotelDetails = (hotelId?: string | number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ petDetailsPageLoader: true })
    this.apiCallIdSeeHotelDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hotesListApiEndPoint}/${hotelId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  goBackHotelsListPage = async () => {
    const pagecomingFrom = await getStorageData('pagecomingFrom')
    if (pagecomingFrom === 'landingPage') {
      this.props.navigation.navigate('LandingPage')
    } else if (pagecomingFrom === 'hotelList') {
      this.props.navigation.navigate('HotelsCatalogue')
    }
    removeStorageData('pagecomingFrom')
  }

  seeAllPhotos = () => {
    this.props.navigation.navigate("AllPhotos")
  }

  checkMoreRoomNeeded = () => {
    let dogRoom = this.state.dogSelectedHotel.reduce((sumofRooms, selectedRoom) => Number(sumofRooms) + Number(selectedRoom.bookings), 0);
    let catRoom = this.state.catSelectedHotel.reduce((sumofRooms, selectedRoom) => Number(sumofRooms) + Number(selectedRoom.bookings), 0);
    if (dogRoom >= this.state.addRoomDog) {
      this.setState({ isDogRoomSelected: false })
    }
    else {
      this.setState({ isDogRoomSelected: true })
    }
    if (catRoom >= this.state.addRoomCat) {
      this.setState({ isCatRoomSelected: false })
    }
    else {
      this.setState({ isCatRoomSelected: true })
    }
  };

  checkroomprice = (event: any, pet: any, index: number, name: string, roomId: number) => {

    if (pet === "dogs") {
      this.setState({
        selectRoomForDogPrice: event,
        selectedRoomDogCount: index + 1
      })
      const selectedHotel = this.state.dogSelectedHotel;
      if (this.state.dogRoomIndex === -1) {
        selectedHotel.push({
          name: name,
          roomId: roomId,
          bookings: index + 1,
          price: event
        })
        this.setState({ dogRoomIndex: this.state.dogSelectedHotel.length - 1 });
      } else {
        selectedHotel[this.state.dogRoomIndex].name = name;
        selectedHotel[this.state.dogRoomIndex].roomId = roomId;
        selectedHotel[this.state.dogRoomIndex].bookings = index + 1;
        selectedHotel[this.state.dogRoomIndex].price = event;
      }
      this.setState({ dogSelectedHotel: selectedHotel }, () => this.checkMoreRoomNeeded());

    } else if (pet === "cats") {
      this.setState({ selectRoomForCatPrice: event, selectedRoomCatCount: index + 1 })
      const selectedHotel = this.state.catSelectedHotel;
      if (this.state.catRoomIndex === -1) {
        selectedHotel.push({
          name: name,
          roomId: roomId,
          bookings: index + 1,
          price: event
        })
        this.setState({ catRoomIndex: this.state.catSelectedHotel.length - 1 });
      } else {
        selectedHotel[this.state.catRoomIndex].name = name;
        selectedHotel[this.state.catRoomIndex].roomId = roomId;
        selectedHotel[this.state.catRoomIndex].bookings = index + 1;
        selectedHotel[this.state.catRoomIndex].price = event;
      }
      this.setState({ catSelectedHotel: selectedHotel }, () => this.checkMoreRoomNeeded());

    }
  }
  getCenter() {
    return this.state.coords[0] && this.state.coords[1]
  };

  getCoords() {
    return this.state.coords[0] && this.state.coords[1];
  };
  getDayCareSpaces = async () => {
    const selectHotelID = await getStorageData('selectedHotelId', true)
    this.getAvailableSpaceCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.hotesListApiEndPoint}/${selectHotelID}?check_in_date=${this.state.checkInDateError}&check_out_date=${this.state.checkOutDateError}`
    });
  };

  handleDilogOpen = async () => {
    let token = await getStorageData("authToken")
    window.scrollTo({
      top: 0
    });

    if (!token) {
      this.setState({ tokenStatus: true })
    } else {
      this.setState({ isOpenDayCareDialog: !this.state.isOpenDayCareDialog, modalError: "" },
        () => {
          this.checkError();
          this.getDayCareSpaces();
        }
      );
    }
  };

  handleDialogClose = () => {
    this.setState({
      isOpenDayCareDialog: !this.state.isOpenDayCareDialog,
      totalPrice: 0,
      selectedDays: [],
      selectedPricingType: "OneDay",
      additionalDetails: "",
      petId: [],
      displayPrice: "0",
      numberOfWeeks: 1,
      petName: [],
      dayCareDogCount: 0
    });
  };

  trimExtra = (paramString: string) => {
    return paramString.slice(0, -1);
  };
  getDayCarePrice = () => {
    return String((Number(this.state.displayPrice) * this.state.numberOfWeeks).toFixed(2));
  };
  showAdditionalService = () => {
    return (this.state.hotelType === "daycare_services" && this.state.dayCareFormSubmited && this.state.addRoomDog > 0) ||
      (this.state.hotelType !== "daycare_services" && this.state.addRoomCat > 0) ||
      (this.state.hotelType !== "daycare_services" && this.state.addRoomDog > 0);
  };

  getDogRoomDetails = () => {
    return this.state.hotelType !== "daycare_services" && this.state.dogSelectedHotel.length > 0 && this.state.addRoomDog !== 0;
  };
  totalPets = (value: { isAllPets: boolean; }) => {
    if (!value.isAllPets) {
      return 1;
    }
    return this.state.addRoomCat + this.state.addRoomDog;
  };
  getRoomPriceForCats = () => {
    return this.state.hotelType !== "daycare_services" && this.state.addRoomCat !== 0;
  };
  getUserPets = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userAllPetsListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userPetsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  userPetListApiResponce = (responseJson: { data: Array<PetData> } & { errors: [] }) => {
    if (responseJson && !responseJson?.errors) {
      this.setState({ userPetList: responseJson?.data });
    }
    else if (responseJson?.errors) {
      this.setState({ userPetList: [] })
    }
  };

  getDayCareServiceCost = async () => {
    const selectHotelID = await getStorageData('selectedHotelId', true);

    this.userDayCareApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.dayCareEndpoint.replace("{hotelId}", selectHotelID)}`
    });

  };

  isDayCareShow = () => {
    return !!(this.state.hotelType === "daycare_services" &&
      this.state.checkInDate &&
      this.state.checkOutDate &&
      (this.state.addRoomDog || this.state.addRoomCat));
  };

  apiCall = async (apiData: APIPayloadType) => {
    const { method, endPoint, body, type } = apiData;

    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  dayCareResponce = (responseJson: DayCareServiceCosts & { error: string }) => {
    if (responseJson?.day_care_services) {
      this.setState({
        priceList: responseJson.day_care_services.data[0]
      })
    }
  };
  checkAvailabilityResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      if (responseJson.status === 'ok') {
        this.setState({
          checkAvailability: {
            isAvailable: true,
            availabilityMessage: 'Rooms available',
          }
        })
        this.OnShoppingCart()
      } else if (responseJson.status === 'unavailable') {
        this.setState({
          checkAvailability: {
            isAvailable: true,
            availabilityMessage: 'Some rooms are not available',
          },
        });
      }
    }
  };
  closeAvailabilityToaster = () => {
    this.setState({
      checkAvailability: {
        isAvailable: false,
        availabilityMessage: '',
      },
    })
  }
  handleAdditionalDetailsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    this.setState({ additionalDetails: value });
  }

  openPopUp = () => {
    this.setState({ openPetPopup: true });
  };

  closePopup = () => {
    this.setState({ openPetPopup: false });
  };

  checkError = () => {
    if (this.state.selectedPricingType === "OneDay" && this.state.totalDay > 8) {
      this.setState({ dateErrorText: "NOTE:  The date range for a One Time Booking cannot exceed 7 days, but Repeat Weekly Bookings can extend further"});
    } else {
      this.setState({ dateErrorText: "" });
    }
  };

  handleSelectedType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedValue = event.target.value;
    this.setState({
      selectedPricingType: changedValue
    }, () => {
      this.checkError();
      this.checkDisplayPrice();
      const dayToSelect: number = Math.floor(this.state.totalDay);
      const chckInSelect = this.state.checkInDate.getDay();
      let newDate = 0, getDay = chckInSelect;
      const wholeWeekDays = [0, 0, 0, 0, 0, 0, 0];
      while (newDate <= dayToSelect) {
        wholeWeekDays[getDay] += 1;
        getDay = (getDay + 1) % 7;
        newDate += 1;
      }
      if(wholeWeekDays.every((value: number) => value === wholeWeekDays[0])) {
        this.setState({ modalError: "" })
      }
      else {
        if(this.state.selectedPricingType === "Weekly") {
          this.setState({ modalError: "These days do not match the criteria." })
        } else if(this.state.selectedPricingType === "OneDay") {
          this.setState({ modalError: "" })
        }
      }
    });
  };

  addRoomOnClick = (selceteddPetId: string, selectedPetName: string, selectedPetType: string) => {
    if (this.state.petId.includes(selceteddPetId)) {
      if (selectedPetType === "dog") {
        this.setState({ dayCareDogCount: this.state.dayCareDogCount - 1 });
      }
      if (selectedPetType === "cat") {
        this.setState({ dayCareCatCount: this.state.dayCareCatCount - 1 });
      }
      this.setState((prevState) => {
        const updatedPetId = prevState.petId.filter(petDataId => petDataId !== selceteddPetId);
        const updatedPetName = prevState.petName.filter(petDataName => petDataName !== selectedPetName);
        return { petId: updatedPetId, petName: updatedPetName };
      }, () => { this.getSelectFeildText() });
    } else {
      if (selectedPetType === "dog") {
        this.setState({ dayCareDogCount: this.state.dayCareDogCount + 1 });
      }
      if (selectedPetType === "cat") {
        this.setState({ dayCareCatCount: this.state.dayCareCatCount + 1 });
      }
      this.setState((prevState) => {
        const newData = [...prevState.petId, selceteddPetId];
        const newNameData = [...prevState.petName, selectedPetName];
        return { petId: newData, petName: newNameData };
      }, () => { this.getSelectFeildText() });
    }

  };

  addSelectedDay = (dayIndex: number) => {
    if (this.state.selectedDays.includes(dayIndex)) {
      this.setState((prevState) => {
        const updatedDays = prevState.selectedDays.filter((dayId) => dayId !== dayIndex);
        return { selectedDays: updatedDays };
      }, () => this.checkDisplayPrice());
    } else {
      this.setState((prevState) => {
        const newData = [...prevState.selectedDays, dayIndex];
        return { selectedDays: newData };
      }, () => this.checkDisplayPrice());
    }
  };

  getSelectFeildText = () => {
    if (this.state.petName.length === 0) {
      return "Select";
    } else {
      let countDogAndCat = "";
      this.state.petName.forEach((selectedPet: string, petIndex) => {
        if (petIndex > 0 && petIndex < this.state.petName.length - 1) {
          countDogAndCat = countDogAndCat + ", " + selectedPet;
        } else if (petIndex === 0) {
          countDogAndCat = selectedPet;
        } else {
          countDogAndCat = countDogAndCat + " and " + selectedPet;
        }
      })
      return countDogAndCat;
    }
  };

  checkDisplayPrice = () => {
    this.checkDays();
    const onetimePrices: (string | null)[] = [
      this.state.priceList.attributes.price_for_one_day,
      this.state.priceList.attributes.price_for_two_days,
      this.state.priceList.attributes.price_for_three_days,
      this.state.priceList.attributes.price_for_four_days,
      this.state.priceList.attributes.price_for_five_days,
      this.state.priceList.attributes.price_for_six_days,
      this.state.priceList.attributes.price_for_seveen_days
    ];
    const weeklyPrices: (string | null)[] = [
      this.state.priceList.attributes.weekly_price_for_one_day,
      this.state.priceList.attributes.weekly_price_for_two_days,
      this.state.priceList.attributes.weekly_price_for_three_days,
      this.state.priceList.attributes.weekly_price_for_four_days,
      this.state.priceList.attributes.weekly_price_for_five_days,
      this.state.priceList.attributes.weekly_price_for_six_days,
      this.state.priceList.attributes.weekly_price_for_seveen_days
    ];
    if (this.state.selectedPricingType === "OneDay") {
      this.setState({ displayPrice: onetimePrices[this.state.selectedDays.length - 1] ?? "0" });
    } else if (this.state.selectedPricingType === "Weekly") {
      this.setState({ displayPrice: weeklyPrices[this.state.selectedDays.length - 1] ?? "0" });
    }
  };

  getDatesOnSpecificDays() {
    function formatDate(date: any) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = (date.getDate()).toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    const daysOfWeek = this.state.selectedDays.map(currentday => currentday === 6 ? 0 : currentday + 1);
    const start = new Date(this.state.checkInDateError);
    const end = new Date(this.state.checkOutDateError);
    let matchingDates = [];
    let currentDate = start;
    while (currentDate < end) {
      const dayOfWeek = currentDate.getDay();
      if (daysOfWeek.includes(dayOfWeek)) {
        matchingDates.push(formatDate(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return matchingDates;
  }

  checkAvailability = () => {
    function getSpacesByDate(givenDate: string, dataArray: { [key: string]: number }[]) {
      let spaces = 0;
      dataArray.forEach(dateObj => {
        const date = Object.keys(dateObj)[0];
        if (givenDate === date) {
          spaces = dateObj[date];
        }
      });
      return spaces;
    };

    let selectedDates = this.state.selectedPricingType === "Weekly" ? this.getDatesOnSpecificDays() : this.getDatesOnSpecificDays().splice(0, this.state.selectedDays.length);
    let flag = false;
    selectedDates.forEach(date => {
      const spaces = getSpacesByDate(date, this.state.availableSpace);
      if (spaces < this.state.addRoomDog) {
        flag = true;
      }
    });
    return flag;
  };

  handleContinue = () => {
    if (this.state.petId.length === 0) {
      this.setState({ modalError: "Please select pets for daycare service" });
    } else if (this.state.addRoomDog !== this.state.dayCareDogCount) {
      this.setState({ modalError: "Please select correct number and type of pets for daycare service" });
    } else if (this.state.selectedDays.length === 0) {
      this.setState({ modalError: "Please select days for daycare service" });
    } else if (this.state.additionalDetails === "") {
      this.setState({ modalError: "Please enter additional details" });
    } else if (this.state.totalDay && this.state.totalDay < 6 && this.state.selectedPricingType === "Weekly") {
      this.setState({ modalError: "Service cannot be repeated in the given date range" });
    } else if (this.state.dateErrorText) {
      this.setState({ modalError: "" });
    } else if (this.state.availableSpace !== null && this.checkAvailability()) {
      this.setState({ modalError: `${this.getSpaceText()}` });
    } else {
      let daycareDays;
      if (this.state.selectedPricingType === "Weekly") {
        daycareDays = this.state.numberOfWeeks * this.state.selectedDays.length;
      } else {
        daycareDays = this.state.selectedDays.length;
      }
      this.setState({
        totalPrice: Number(this.state.displayPrice) * this.state.petId.length * this.state.numberOfWeeks,
        dayCareFormSubmited: true,
        dayCareDays: daycareDays,
        isOpenDayCareDialog: !this.state.isOpenDayCareDialog,
        modalError: ""
      });
    }
  };
  handleError = () => {
    return (this.state.conditionSlack && this.state.hotelType !== "daycare_services");
  };

  handleToastError = () => {
    return (this.state.isSubmitted &&
      this.state.petId.length === 0 &&
      this.state.selectedDays.length === 0 &&
      this.state.additionalDetails === "" &&
      this.state.hotelType === "daycare_services");
  };

  getCatRoomPrice = () => {
    return (this.state.hotelType !== "daycare_services" && this.state.selectRoomForCatPrice !== 0 && this.state.addRoomCat !== 0);
  };

  calculateDayCarePrice = () => {
    return (Number(this.state.displayPrice) * this.state.petId.length * this.state.numberOfWeeks).toFixed(2);
  }
  correctDogRoom = (roomIndex: number) => {
    return this.state.dogSelectedHotel[roomIndex].name.replace(/_/g, ' ');
  };

  correctCatRoom = (roomIndex: number) => {
    return this.state.catSelectedHotel[roomIndex].name.replace(/_/g, ' ');
  };

  isChecked = (selectedFor: string, value: string) => {
    return (selectedFor === "dogs" && this.state.dogRoomIndex > -1 && this.state.dogSelectedHotel[this.state.dogRoomIndex].price === value) ||
      (selectedFor === "cats" && this.state.catRoomIndex > -1 && this.state.catSelectedHotel[this.state.catRoomIndex].price === value);
  };


  handleRemoveCatRoom = (catRoomIndex: number) => {
    if (this.state.catSelectedHotel.length > 1) {
      this.setState((prevState) => {
        let newCatRoomList = [...prevState.catSelectedHotel];
        newCatRoomList.splice(catRoomIndex, 1);
        return { catSelectedHotel: newCatRoomList };
      }, () => {
        this.checkMoreRoomNeeded();
        this.calculateTotalPrice();
      });
    } else {
      this.setState({ conditionSlack: true, slackData: "There should be atleast one room for the added pets." })
    }
  };

  handleRemoveDogRoom = (dogRoomIndex: number) => {
    if (this.state.dogSelectedHotel.length > 1) {
      this.setState((prevState) => {
        let newDogRoomList = [...prevState.dogSelectedHotel]
        newDogRoomList.splice(dogRoomIndex, 1)
        return { dogSelectedHotel: newDogRoomList }
      }, () => {
        this.checkMoreRoomNeeded()
        this.calculateTotalPrice()
      })
    } else {
      this.setState({ conditionSlack: true, slackData: "There should be atleast one room for the added pets." })
    }
  };


  getPrice = () => {
    if (this.state.hotelType === "daycare_services") {
      return this.state.hotelDetails.data?.attributes.day_care_services?.data[0].attributes.price_for_one_day;
    }
    return this.state.hotelDetails.data?.attributes.budget_per_day
  };
  getRoomAvailability = async () => {
    let token = await getStorageData("authToken");
    const { catRoomId, dogRoomId, checkInDateError, checkOutDateError } = this.state;
    const header = {
      "Content-Type": "application/json",
      token: token
    };

    let endpoint = `${configJSON.avalabilityApiEndPoint}?type=week&start_date=${checkInDateError}&end_date=${checkOutDateError}`;
    const roomIds = [catRoomId, dogRoomId].filter(Boolean);
    const roomIdsQuery = roomIds.map(id => `room_ids[]=${id}`).join('&');
    endpoint += roomIdsQuery ? `&${roomIdsQuery}` : '';

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.apiCallIdCheckAvailability = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  checkDays = () => {
    this.getDayCareSpaces();
    if (this.state.selectedPricingType === "Weekly") {
      const dayList = [0, 0, 0, 0, 0, 0, 0];
      const startDay = this.state.checkInDate.getDay();
      const totalDay: number = Math.floor(this.state.totalDay);
      let date = 0, getday = startDay;
      while (date <= totalDay) {
        dayList[getday] += 1;
        getday = (getday + 1) % 7;
        date += 1;
      }
      const daySet: Set<number> = new Set();
      dayList.forEach((dayFreq: number, dayIndex: number) => {
        dayIndex = dayIndex > 0 ? dayIndex - 1 : 6;
        if (this.state.selectedDays.includes(dayIndex)) {
          daySet.add(dayFreq);
        }
      });
      if(dayList.every((value: number) => value === dayList[0])) {
        this.setState({ modalError: "" })
      }
      else {
        if(this.state.selectedPricingType === "Weekly") {
          this.setState({ modalError: "These days do not match the criteria." })
        }
      }
      if (daySet.size === 0) {
        this.setState({ numberOfWeeks: 0 });
        return false;
      }
      this.setState({ numberOfWeeks: Array.from(daySet)[0], modalError: "" }, () => this.calculateTotalPrice());
      return false;
    } else {
      this.setState({ numberOfWeeks: 1, modalError: "" }, () => this.calculateTotalPrice());
      return false;
    }
  };
  getNumberOfAllWeeks = () => {
    if (this.state.selectedPricingType === "Weekly") {
      if (this.state.numberOfWeeks > 1) {
        return `X ${this.state.numberOfWeeks} Weeks`
      }
      else {

        return "X 1 Week"
      }
    } else {
      if(this.state.dayCareDays > 1) {
        return `for ${this.state.dayCareDays} Days`
      } else {
        return `for 1 Day`
      }
    }
  };
  getAddCatsIconColor = () => {
    return this.state.catSelectedHotel[0].name === "" ? "disabled" : "secondary";
  };
  getAddDogsIconColor = () => {
    return this.state.dogSelectedHotel[0].name === "" ? "disabled" : "secondary";
  };
  checkDaysOneTime = () => {
    if (this.state.totalDay && this.state.totalDay < 7 && this.state.selectedPricingType === "OneDay") {
      let startDay = this.state.checkInDate.getDay();
      startDay = startDay >= 1 ? startDay - 1 : 6;
      const totalDay = Math.floor(this.state.totalDay);
      const dayList = [0, 0, 0, 0, 0, 0, 0];
      let date = 0, getday = startDay;
      while (date < totalDay) {
        dayList[getday] += 1;
        getday = (getday + 1) % 7;
        date += 1;
      }

      return this.state.selectedDays.some((daySelected: number) => dayList[daySelected] === 0);
    }
    return false;
  };

  getHotelRooms = async (hotelId: string) => {
    this.hotelRoomsCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.hotelRoomsEndpoint.replace("{hotelId}", hotelId)}`
    });
  };

  checkActiveRooms = (responseJson: Room[]) => {
    const allRooms = [...responseJson];
    if (responseJson) {
      const dogRooms = this.filterRooms(allRooms, "Dog");
      const catRooms = this.filterRooms(allRooms, "Cat");
      if (dogRooms.length === 0) {
        this.setState({
          roomErrorDogMessage: "There are no rooms active for dogs in this hotel"
        });
      }
      if (catRooms.length === 0) {
        this.setState({
          roomErrorCatMessage: "There are no rooms active for cats in this hotel"
        });
      }
    }

  };

  getRoomPrice = (selectRoomForCatPrice: number, totalDayStay: number) => {
    const roomCost = (selectRoomForCatPrice * totalDayStay);
    return roomCost.toFixed(2);
  }

  getSpaceText = () => {
    return "These many space are not available for selected date";
  }

  async componentWillUnmount(): Promise<void> {
    removeStorageData('serchBarData');

  }
  getCountryCode = (countrycode: string) => {
    return countrycode.split("(")[1].split(")")[0];
  }
  calculateServicePrice = (value: { isDailyService: boolean; isAllPets: boolean; price: number; perDayPrice: number; }, totalDayStay: number) => {
    if (value.isDailyService && !value.isAllPets) {
      return ((value.perDayPrice * totalDayStay * this.totalPets(value)).toFixed(2));
    } else if (value.isDailyService && value.isAllPets) {
      return ((value.perDayPrice * 1 * totalDayStay).toFixed(2));
    }else if (!value.isDailyService && value.isAllPets) {
      return ((value.perDayPrice * 1).toFixed(2));
    } else {
      return ((value.perDayPrice * this.totalPets(value)).toFixed(2));

    }
  };

  getPetServiceType = (service: { service_provided_type: string; charge_type: string; }) => {
    const serviceType = service.service_provided_type === "OneTime" ? "One Time" : "Daily";
    const chargeType = service.charge_type === "All" ? "All Pets" : "Per pet";
    return [serviceType, chargeType].join(", ");
  };

  getPets = (value: any) => {
    return this.totalPets(value) === 1 ? "Pet" : "Pets";
  }

  getHotelReviewsStatus = async (order_id: string) => {
    this.apiCallIdHotelReviews = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.reviewsEndPoint.replace("{orderId}", order_id)}`
    });
  };

  clearPreviousData=()=>{
    if (this.state.hotelType === 'daycare_services') {
      this.setState({
        totalPrice: 0,
        selectedDays: [],
        additionalDetails: "",
        petId: [],
        displayPrice: "0",
        numberOfWeeks: 1,
        petName: [],
        dayCareDogCount: 0
      });
    }
  }
  // Customizable Area End
}


