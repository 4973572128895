Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.getPaymentMethod = "GET";

  exports.stripePaymentIntent = "bx_block_stripe_integration/payments"
  
  exports.stripePublishableKey = "pk_test_51PPqaAAEXuKIPmlQoPt0dKiPfUUExBvm6nxlIPzsWbZSTDUtrbEL2rFtnzrXOpAnAJigfzfDhp0HASLS574eXNIY00LJSHDx1t"
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url
  exports.verifyPayment='bx_block_stripe_integration/payment_methods'
  exports.confirmPaymentIntent="bx_block_stripe_integration/confirm";
  exports.confirmSubscriptionPaymentIntent="bx_block_user_subscriptions/subscriptions/payment"
  exports.confirmSubscriptionConfirmPaymentIntent="bx_block_user_subscriptions/subscriptions/confirm";
  exports.stripeTermsAndConditionsApiEndPoint="bx_block_user_subscriptions/stripe_terms_and_conditions"

  exports.paymentErrorText={
    failed:"Payment Failed",
    verifyCard:"Unable to verify the card,Please enter different card details",
    paymentIntent:"Unable to make payment"
  }
  exports.cardPayment="Card Payment"
  exports.unableVerify="Unable to verify card details"
  // Customizable Area End
  