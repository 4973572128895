//@ts-nocheck
import React from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import {
  Container,
  Typography,
  FormControl,
  Paper,
  Select,
  MenuItem,
  Menu,
  withStyles,
  Box,
  Divider,
  Avatar,
  ClickAwayListener,
  Button
} from "@material-ui/core";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SideNav from "../../../components/src/SideNavbar";
import Footer from "../../../components/src/Footer.web";
import { styled } from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';

// Customizable Area End

import ContentManagementControllerBo, {
  Props,
  configJSON,
} from "./ContentManagementControllerBo.web";
import { profileIcon } from "../../user-profile-basic/src/assets";
import { boxShadows, colors, theme } from "../../../components/src/theme";
import { editPencilIcon } from "../../settings2/src/assets";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";


// Customizable Area Start

const CustomSelect = styled(Box)({
  "& .MuiInputBase-root, .MuiOutlinedInput-root": {
    height: "44px",
    width: "100%",
    background: "#E0F4FC",
    border: "1px solid #B2E4F8",
    borderRadius: "8px",
    marginTop: "1.5%",
    cursor: "pointer"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10.5px 14px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "& .MuiSelect-select:focus": {
    background: "transparent",
  },
});

// Customizable Area End


export default class ContentManagementBo extends ContentManagementControllerBo {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start


    // Customizable Area End
  }

  // Customizable Area Start
  getValError = (field: string[]): React.ReactNode | undefined => {
    const isInValidBo = field.findIndex(
      (item) => this.state.error[`${item}Error`]
    );

    if (isInValidBo >= 0) {
      return (
        <span style={{ color: "red" }}>
          {this.state.MesErrProfile[`${field[isInValidBo]}`]}
        </span>
      );
    }
    return undefined;
  };
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
        <>
          <NavigationMenu navigation={this.props.navigation} active={0}
            iconPage={'profileBo'}
            id="" />
          <div style={secondformstyle.profileSection as React.CSSProperties} className='mobile-margin'>
            <Container style={{ padding: "0% 10%", fontFamily: "inter", backgroundColor: "#E0F4FC" }}>
              <Paper
                elevation={3}
                style={{
                  margin: "auto",
                  borderBottomRightRadius: "35px",
                  width: "100%",
                  fontFamily: "inter",
                  marginBottom: "10%"
                }}
              >
                <Grid
                  container
                  style={secondformstyle.maingrid as React.CSSProperties}
                >
                  <Grid
                    item
                    style={secondformstyle.firstgrid as React.CSSProperties}
                    lg={3}
                    xs={12}
                    sm={3}
                  >
                    <Avatar
                      src={this.state.imageUrl}
                      style={{
                        width: "112px",
                        height: "112px",
                        position: "absolute",
                        left: "16%",
                        backgroundColor: '#B2E4F8',
                        border: "solid 5px  #F0E5FF",
                        top: "2%"
                      }}
                    />
                    <img src={editPencilIcon} width={'40px'} height={'40px'} style={{ position: 'absolute', right: '35%', bottom: "22%" }}
                      onClick={this.handleEditPencilIcon} data-test-id='edit_pencil'
                    />
                    {this.state.editPencilIconStatus && <ClickAwayListener onClickAway={() => this.setState({ editPencilIconStatus: false })}>
                      <Box style={secondformstyle.editPopup}>

                        <label htmlFor="icon-button-file">
                          <input
                            accept="image/*"
                            name="imageUplode"
                            type="file"
                            id="icon-button-file"
                            onChange={this.handleFileChange}
                            style={{ display: "none" }}
                            ref={this.fileInputRef}
                            data-test-id="handleFileChangeImage"
                          />

                          <MenuItem
                            style={{ ...secondformstyle.menuItemText, backgroundColor: this.state.hovermouse === 2 ? "#ED2258" : "#fff" }}
                            data-test-id="edit_pencil"
                            onMouseEnter={() => this.setState({ hovermouse: 2 })}
                            onMouseLeave={() => this.setState({ hovermouse: 0 })}
                          >
                            < EditIcon style={{ marginRight: "9%", color: "black !important" }} />
                            {this.state.photoUploaded ? 'Edit Photo' : "Add Photo"}
                          </MenuItem>
                        </label>
                        {this.state.photoUploaded && (
                          <Button
                            onMouseLeave={() => this.setState({ hovermouse: 0 })}
                            onMouseEnter={() => this.setState({ hovermouse: 1 })}
                            style={{ ...secondformstyle.menuItemText, backgroundColor: this.state.hovermouse === 1 ? "#ED2258" : "#fff" }}
                            onClick={this.handleDeleteImage}
                            data-test-id="delete_image"
                          >
                            <DeleteOutlineIcon style={{ marginRight: "10%" }} />
                            Delete Photo
                          </Button>)}
                      </Box>
                    </ClickAwayListener>}
                  </Grid>
                  <Grid
                    item
                    lg={9}
                    sm={9}
                    xs={12}
                    style={{ padding: "20px", marginTop: "2%" }}
                  >
                    <Typography component={'div'} style={{ fontSize: "36px", color: "#fff", fontFamily: "inter", fontWeight: 700 }}>
                      Welcome,<br />
                    </Typography>
                    <Typography component={'div'} style={{ fontSize: "30px", color: "#fff", fontFamily: "inter", fontWeight: 400 }}>
                      We are glad to have you as part of the &nbsp;
                    </Typography>
                    <Typography color="secondary" component={'div'} style={{ fontSize: "36px", fontFamily: "inter", fontWeight: 400 }}>
                      PETHOTEL &nbsp;
                      <Typography display="inline" component={'div'} style={{ fontSize: "30px", color: "#fff", fontFamily: "inter", fontWeight: 400 }}>

                        Team
                      </Typography>
                    </Typography >

                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} style={{ paddingLeft: "7%" }}>
                  <span
                    style={{ fontWeight: 700, fontSize: "30px", color: "#334155", fontFamily: "inter" } as React.CSSProperties}
                  >
                    Business Owner Information
                  </span>
                </Grid>
                <Grid container style={{ padding: "3% 7% 7%", display: "flex" } as React.CSSProperties}>

                  <Grid item xs={12} sm={3} lg={4}>
                    <span
                      style={{ fontWeight: 400, fontSize: "24px", color: "#0F172A", fontFamily: "inter" } as React.CSSProperties}
                    >
                      Personal Information
                    </span>
                  </Grid>
                  <Grid item sm={9} lg={8} xs={12} >
                    <div style={{ marginBottom: "5px" }}><label style={secondformstyle.lableText as React.CSSProperties}>First Name*</label></div>
                    <div style={{
                      width: "100%",
                      display: "flex", height: "41px",
                      borderRadius: "8px"
                    }}>
                      <div
                        style={{ ...secondformstyle.countryPhnameselect, width: "15%", cursor: "pointer", } as React.CSSProperties}
                        onClick={this.handleGenderopen}
                      >
                        <span style={secondformstyle.countrySpan as React.CSSProperties}>{this.state.gender === null ? "Mr." : this.state.gender}</span>
                        <ExpandMoreIcon
                          style={secondformstyle.expendIconStyle as React.CSSProperties}
                        />
                      </div>

                      <Menu
                        variant="outlined"
                        anchorEl={this.state.anchorElgender as Element | null}
                        open={Boolean(this.state.anchorElgender)}
                        onClose={() => this.handleGenderClose("Mr.")}
                        data-test-id="handlegenderchange"
                        value={this.state.gender}
                        onChange={this.handleGenderClose}
                        name="gender"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'left',
                          horizontal: 'center',
                        }}
                        getContentAnchorEl={null}
                        PaperProps={{
                          style: {
                            width: "86px",
                            padding: "0px 10px",
                            cursor: "pointer",
                            maxHeight: "219px",
                            overflowY: "auto",
                            scrollbarColor: "#5B8188 transparent",
                            scrollbarWidth: "thin",
                            marginTop: "5px",
                            borderRadius: "8px",
                            borderBottomRightRadius: "50% !important"
                          },
                        }}
                      >
                        <MenuItem value="Mr." onClick={() => this.handleGenderClose("Mr.")}
                          style={{ backgroundColor: this.state.gender === "Mr." ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
                          data-test-id="mrvalue"
                        >Mr.
                        </MenuItem>
                        <Divider style={{ margin: "2px", backgroundColor: "#B2E4F8" }} />
                        <MenuItem value="Miss" onClick={() => this.handleGenderClose("Miss")}
                          style={{ backgroundColor: this.state.gender === "Miss" ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
                          data-test-id="missvalue"
                        >Miss
                        </MenuItem>
                        <Divider style={{ margin: "2px", backgroundColor: "#B2E4F8" }} />
                        <MenuItem value="Mrs." onClick={() => this.handleGenderClose("Mrs.")}
                          style={{ backgroundColor: this.state.gender === "Mrs." ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
                          data-test-id="mrsvalue"
                        >Mrs.
                        </MenuItem>
                        <Divider style={{ margin: "2px", backgroundColor: "#B2E4F8" }} />
                        <MenuItem value="Mx." onClick={() => this.handleGenderClose("Mx.")}
                          style={{ backgroundColor: this.state.gender === "Mx." ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
                          data-test-id="mxvalue"
                        >Mx.
                        </MenuItem>
                      </Menu>
                      <input
                        name="fullName"
                        value={this.state.fullName}
                        onChange={this.handleInputChange}
                        data-test-id="fullName"
                        placeholder="First Name"
                        style={{
                          border: "1px solid #B2E4F8",
                          paddingLeft: "5px",
                          borderLeft: "none",
                          borderBottomRightRadius: "8px",
                          width: "95%",
                          borderTopRightRadius: "8px",
                          fontSize: "16px",
                        } as React.CSSProperties}
                      ></input>
                    </div>
                    {this.getValError(["fullName"])}
                    <div style={{ marginTop: "3%" }}>
                      <label
                        style={secondformstyle.lableText as React.CSSProperties}
                      >
                        Surname*
                      </label><br />
                      <input
                        name="lastName"
                        onChange={this.handleInputChange}
                        data-test-id="lastName"
                        value={this.state.lastName}
                        placeholder="Last Name"
                        style={secondformstyle.inputboxEmail}
                      />
                    </div>
                    {this.getValError(["lastName"])}

                    <div style={{ marginTop: "3%" }}>
                      <label
                        style={secondformstyle.lableText as React.CSSProperties}
                      >
                        Email*
                      </label><br />
                      <input
                        name="email"
                        onChange={this.handleInputChange}
                        data-test-id="email"
                        value={this.state.email}
                        placeholder="kevinbarrett@iamcat.com"
                        style={secondformstyle.inputboxEmail}
                      />
                    </div>
                    {this.getValError(["email"])}
                    <div style={{ marginTop: "3%" }}>
                      <div style={{ marginBottom: "5px" }}>
                        <label style={secondformstyle.lableText as React.CSSProperties}>Phone Number*
                        </label>
                      </div>

                      <div style={{ display: "flex", width: "100%" }}>
                        <div
                          style={secondformstyle.countryPhnameselect as React.CSSProperties}
                          onClick={this.handelCountryOpen}
                        >
                          <span style={secondformstyle.countrySpan as React.CSSProperties}>{`${this.state.phNoCountry}`}</span>
                          <ExpandMoreIcon
                            style={secondformstyle.expendIconStyle as React.CSSProperties}
                          />
                        </div>
                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.anchorEl as Element | null}
                          keepMounted
                          open={Boolean(this.state.anchorEl)}
                          onClose={() => this.handelPhCountryCloses("", "")}
                          data-test-id="handleSelectClose"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          getContentAnchorEl={null}
                          PaperProps={{
                            style: {
                              width: "13.5%",
                              padding: "0px 10px",
                              cursor: "pointer",
                              maxHeight: "300px",
                              overflowY: "auto",
                              scrollbarColor: "#5B8188 transparent",
                              scrollbarWidth: "thin",
                              marginTop: "5px",
                              borderRadius: "8px",
                              borderBottomRightRadius: "50% !important"
                            },
                          }}
                        >
                          {configJSON.countries.map((value: { label: string; phone: string; }) => (
                            <>
                              <MenuItem data-test-id="countrymap" value={value.label} onClick={() => this.handelPhCountryCloses(value.label, value.phone)}
                                style={{ borderRadius: "8px", width: "90%", height: "44px" }}

                              >
                                {`${value.label} ${value.phone}`}

                              </MenuItem>
                              <Divider style={{ margin: "2px", backgroundColor: "#B2E4F8" }} />
                            </>
                          ))}

                        </Menu>
                        <input
                          name="phoneNumber"
                          value={this.state.phoneNumber !== null ? this.state.phoneNumber : ""}
                          data-test-id="phoneNumber"
                          placeholder="Phone Number"
                          onChange={this.handleInputChange}
                          style={secondformstyle.thirdFormInputStyle as React.CSSProperties}
                        />
                      </div>
                      {this.getValError(["phoneNumber"])}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4} sm={3} style={{ marginTop: "4.3%" }}>
                    <span
                      style={{ fontWeight: 400, fontSize: "24px", color: "#0F172A", fontFamily: "inter" }}
                    >
                      Personal Address
                    </span>
                  </Grid>
                  <Grid item sm={9} lg={8} xs={12} style={{ marginTop: "2%" }}>
                    <form>

                      <div style={{ marginTop: '3%' }}>
                        <label style={secondformstyle.lableText as React.CSSProperties}>Address Line 1*</label>
                        <input
                          onChange={this.handleInputChange}
                          data-test-id='streetAddress'
                          value={this.state.streetAddress}
                          name='streetAddress'
                          style={secondformstyle.inputbox}
                          placeholder='E.g. 125 High Street'
                        ></input>
                      </div>
                      {this.getValError(["streetAddress"])}
                      <div style={{ marginTop: "3%" }}>
                        <label style={secondformstyle.lableText as React.CSSProperties}>Address Line 2 </label>
                        <input
                          value={this.state.flatNo}
                          onChange={this.handleInputChange}
                          data-test-id="handleInputChange"
                          placeholder="E.g. Kensington"
                          name="flatNo"
                          style={secondformstyle.inputbox as React.CSSProperties}
                        ></input>
                      </div>

                      <div style={{ marginTop: '3%' }}>
                        <label style={secondformstyle.lableText as React.CSSProperties}>Town / City*</label>
                        <input
                          data-test-id="city"
                          onChange={this.handleInputChange}
                          name="city"
                          value={this.state.city}
                          placeholder="E.g. London "
                          style={secondformstyle.inputbox}
                        ></input>
                      </div>
                      {this.getValError(["city"])}

                      <div style={{ marginTop: '3%' }}>
                        <label style={secondformstyle.lableText as React.CSSProperties}>County</label>
                        <input
                          data-test-id="handleInputChange"
                          onChange={this.handleInputChange}
                          name="county"
                          value={this.state.county}
                          placeholder="E.g. Greater London "
                          style={secondformstyle.inputbox}
                        ></input>
                      </div>

                      <div style={{ marginTop: "3%" }}>
                        <label style={secondformstyle.lableText as React.CSSProperties}>Postcode*</label>
                        <input
                          placeholder="E.g. SWA1A 1AA"
                          onChange={this.handleInputChange}
                          value={this.state.postCode}

                          data-test-id="postCode"
                          name="postCode"
                          style={secondformstyle.inputbox}
                        ></input>
                      </div>
                      {this.getValError(["postCode"])}


                      <div style={secondformstyle.mainContainerSecDiv as React.CSSProperties}>
                        <label style={secondformstyle.lableText as React.CSSProperties}>
                          {`Country / Region `}
                        </label>
                        <br />
                        <CustomSelect>
                          <Select
                            variant="outlined"
                            displayEmpty
                            renderValue={() => (
                              <span style={secondformstyle.textOfOutPut}>
                                {this.state.country}
                              </span>
                            )}
                            MenuProps={{
                              style: { maxHeight: "370px", minHeight: "290px" },
                              disableScrollLock: false,
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                            }}
                            IconComponent={ExpandMoreIcon}
                            onChange={this.handleCountry}
                            data-test-id="handleSelectoptions"
                            value={this.state.country}
                          >
                            {configJSON.countries.map((value: any, index: number) => (
                              <CustomMenuItem key={index} value={value.label}>
                                <div
                                  style={
                                    secondformstyle.slectMenu
                                  }
                                >
                                  {value.label}
                                </div>
                              </CustomMenuItem>
                            ))}
                          </Select>
                        </CustomSelect>
                      </div>
                    </form>
                  </Grid>
                  <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "baseline", height: "40px" }}>
                    <button
                      onClick={this.handleApi}
                      style={secondformstyle.button}
                      data-test-id="handleApi"
                    >
                      <span style={{ marginLeft: "11px" }}>Save and Continue</span>
                      <KeyboardArrowRightIcon style={{ fontSize: '30px' }} />
                    </button>
                  </Box>

                </Grid>
              </Paper>
            </Container>
            <Footer footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />
          </div>

        </>

      </>
      // Customizable Area End

    );
  }
}
// Customizable Area Start

const CustomMenuItem = withStyles((theme) => ({
  root: {
    fontSize: "16px",
    height: "55px",
    borderBottom: "1px solid #CCEDFA",
    "&:hover": {
      background: "transparent",
    },
    "& .MuiListItem-root .MuiListItem-gutters": {
      paddingLeft: "0px",
    },
    "&.Mui-selected": {
      background: "transparent",
      "&:hover": {
        background: "transparent",
      },
    },
    "& .MuiTouchRipple-root": {
      background: "transparent",
    },
  },
}))(MenuItem);

const AlertBox: any = styled(Alert)({
  "&.MuiAlert-standardWarning": {
    color: "#000",
    width: "100%",
    height: 16,
    alignItems: "center",
    fontSize: 16,
    fontWeight: 400,
    backgroundColor: "#FBF3C7"
  },

})

const AutoDiv: any = styled(Box)({

  "& .MuiPaper-elevation8": {
    marginTop: "145px !important"
  },

})

const secondformstyle = {
  textOfOutPut: {
    fontFamily: "inter",
    fontSize: "16px",
    color: "#64748B",
    fontWeight: 400,
  },
  mainContainerSecDiv: {
    marginTop: "20px",
  },
  slectMenu: {
    margin: "8px 0px 9px",
    width: "98%",
    padding: "10px 0px 10px 10px",
  },
  selectedSlectMenu: {
    margin: "8px 0px 9px",
    width: "98%",
    padding: "10px 0px 10px 10px",
    backgroundColor: "#B2E4F8",
    borderRadius: "8px",
  },
  thirdFormInputStyle: {
    border: "1px solid #B2E4F8",
    paddingLeft: "5px",
    width: "60%",
    borderBottomRightRadius: "8px",
    borderLeft: "none",
    borderTopRightRadius: "8px",
    fontSize: "16px",
    height: "41px"
  },
  fontHading: {
    fontSize: "30px",
    fontWeight: "700",
    color: "#334155",
    lineHeight: "40px",
    marginBottom: "0px",
    fontFamily: "inter"
  },

  inputStyle: {
    height: "45px",
    width: "100%",
    paddingLeft: "10px",
    marginTop: "10px",
    borderRadius: "8px",
    border: "1px solid #B2E4F8",
  },
  countryPhnameselect: {
    paddingLeft: "10px",
    width: "40%",
    height: "39px",
    border: "1px solid #B2E4F8",
    borderRadius: "8px 0px 0px 8px",
    backgroundColor: "#E0F4FC",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer"
  },

  numberSelect: {
    cursor: "pointer",
    paddingLeft: "10px",
    width: "100%",
    height: "39px",
    border: "1px solid #B2E4F8",
    borderRadius: "8px",
    backgroundColor: "#E0F4FC",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },

  lableText: {
    fontWeight: "700",
    fontSize: "14px",
    fontFamily: "inter",
    color: "#334155",
  },
  countrySpan: { color: "#64748B", fontSize: "16px", fontWeight: 400, fontFamily: "inter", textTransform: "capitalize" },

  inputbox: {
    width: "100%",
    border: "1px solid #B2E4F8",
    marginBottom: "3px",
    fontSize: "16px",
    borderRadius: "8px",
    height: "38px",
    marginTop: "8px",
    paddingLeft: "10px"
  }, inputboxEmail: {
    width: "100%",
    border: "1px solid #B2E4F8",
    marginBottom: "3px",
    fontSize: "16px",
    borderRadius: "8px",
    height: "38px",
    marginTop: "8px",
    paddingLeft: "10px"
  },
  button: {
    borderRadius: "8px",
    display: "flex",
    color: "white",
    backgroundColor: "#EA0C78",
    marginTop: "15px",
    height: "40px",
    border: "none",
    fontSize: "16px",
    cursor: "pointer",
    maxWidth: "255px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 650,
    // paddingLeft:"17px"
  },
  buttonback: {
    background: "transparent",
    color: "#000",
    height: "40px",
    border: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
    marginTop: "15px",
    cursor: "pointer",
  },
  fullnameinput: {
    fontSize: "16px",
    border: "1px solid #CBD5E1",
    width: "100%",
    marginBottom: "10px",
    borderRadius: "4px",
    height: "40px",
    marginTop: "8px",
  },
  fullnameselect: {
    backgroundColor: "#e8eaf6",
    fontSize: "16px",
    border: "none",
    height: "38px",
    width: "60px",
  },
  lableText: {
    color: "#334155",
    fontFamily: "inter",
    fontSize: "14px",
    fontWeight: 700
  },
  fullnameselect2: {
    border: "none",
    width: "190px",
    backgroundColor: "#e8eaf6",
    fontSize: "16px",
    height: "38px",
  },
  avtar: {
    backgroundColor: "red",
    padding: "15px",
    margin: "15px",
    width: "auto",
    bottom: "-40px",
  },
  firstgrid: {
    color: "#e8eaf6",
    position: "relative",
    justifyContent: "center",
    height: "160px",
    display: "flex",
  },
  maingrid: {
    fontFamily: "inter",
    display: "flex",
    backgroundColor:
      "#54A7C9",
    flexWrap: "wrap-reverse",
    borderBottomRightRadius: "35px",
    minHeight: "180px",
    borderTopLeftRadius: "4px",
    marginBottom: "5%",
    borderTopRightRadius: "4px",
    height: "auto",
  },
  profileSection: {
    marginLeft: "140px", // Adjust based on the width of your sidebar
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    overflowY: "auto", // Allow main reservationContent area to scroll
  },
  editPopup: {
    width: "184px",
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    zIndex: 99,
    borderRadius: "8px",
    left: '47%',
    boxShadow: boxShadows.blueShadow,
    border: "1px solid #E2E8F0",
    padding: '0.2rem',
    top: "52%"
  },
  menuItemText: {
    width: "100%",
    textTransform: "none",
    display: "flex",
    justifyContent: "start",
    color: theme.palette.common.black,
    fontSize: 14,
    padding: '0.5rem',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      background: "red",
      borderRadius: 8
    }
  },


};
// Customizable Area End
