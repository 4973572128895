// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Modal,
  Grid,
  Paper,
  TableContainer,
} from "@material-ui/core";

import { styled, ThemeProvider } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { location, Dog1 } from "../../landingpage/src/assets";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Loader from "../../../components/src/Loader.web";
import { DateObject } from "react-multi-date-picker";

import ReservationHistoryController, {
  Props,
  configJSON,
  Item,
} from "./ReservationHistoryController";

import PetOwnerNavigationMenu from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
import Footer from "../../../components/src/Footer.web";
import { theme } from "../../../components/src/theme";
import { cancel } from "../../shoppingcart/src/assets";
import CustomRating from "../../../components/src/CustomizedComponents.web";
import { PetAttributes, Reservations } from "./types";
import { getReviewMessage } from "../../../components/src/CommonFunctions";
import ReservationDailog from "./ReservationDailog.web";
import { PaymentDetails } from "./ReservationBusinessOwnerController.web";

export default class ReservationHistory extends ReservationHistoryController {
  constructor(props: Props) {
    super(props);
  }


  cusombutton = () => {
    let buttonText;

    if (this.state.buttonstatus === "accepted" || this.state.buttonstatus === "pending") {
      buttonText = "Edit";
    } else if (this.state.buttonstatus === "draft") {
      buttonText = "Proceed";
    } else {
      buttonText = "View";
    }

    return buttonText;
  }


  tableCells = () => {
    return (
      <>
        <TableCell style={webStyle.tableheadcell}>Property & Address</TableCell>
        <TableCell align="center" style={webStyle.tableheadcell}>Check In</TableCell>
        <TableCell align="center" style={webStyle.tableheadcell}>Check Out</TableCell>
        <TableCell align="center" style={webStyle.tableheadcell}>Pets</TableCell>
        {(this.state.buttonstatus === "accepted" || this.state.buttonstatus === "pending") ?
          <TableCell align="center" style={webStyle.tableheadcell}>No. of Days</TableCell>
          : null}
        <TableCell align="center" style={webStyle.tableheadcell}>Total Amount</TableCell>
        {this.state.buttonstatus != "ongoing" && <TableCell align="center" style={webStyle.tableheadcell}>Action</TableCell>}

        <TableCell align="center" style={webStyle.tableheadcell}>Message</TableCell>
      </>
    )
  }

  customTable = () => {

    const renderMessageCell = (chat_id: number) => {
      return (

        <TableCell align="center" style={{ ...webStyle.tabledatastyle, color: "#326478", cursor: "pointer", padding: '0px' }}>
          <MailOutlineIcon data-test-id='chat_icon'
            onClick={() => this.seePoChat(chat_id)}
          />
        </TableCell>
      )
    };

    return (
      <Grid item xs={12} >
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <CustomTable>
            <TableHead>
              <TableRow>
                {this.tableCells()}

              </TableRow>
            </TableHead>

            <TableBody>

              {this.state.itemsList.length > 0 && this.state.itemsList.map((row: Reservations, index) => {
                return (
                  <TableRow >
                    <TableCell scope="column" style={{ ...webStyle.tabledatastyle, display: "flex", padding: '0px' }}>
                      <div style={webStyle.imageBackground}>
                        <img
                          src={row?.data?.attributes?.images[0]?.url}
                          style={{ height: "52px", width: '85px', borderRadius: "8px" }}
                          alt=""
                        />
                      </div>
                      <Box style={{ display: "flex", flexDirection: "column", height: "52px", marginLeft: "5px", width: "220px", padding: '16px 0px' }}>

                        <Typography
                          variant="subtitle2"
                          style={webStyle.hotelTitle as React.CSSProperties}
                        >
                          {row.data.attributes.hotel_information.name}
                        </Typography>
                        <Box style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: 400 }}>
                          <LocationOnOutlinedIcon style={{ color: "#4386A1" }} />
                          <Typography variant="body1" style={webStyle.overFlowWhiteSpace as React.CSSProperties}>
                            {row.data.attributes.hotel_information.hotel_city}, {row.data.attributes.hotel_information.hotel_country_region}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell align="center" style={{ ...webStyle.tabledatastyle, padding: '0px' }}>{this.dateToString(row.data.attributes.check_in_date)}</TableCell>
                    <TableCell align="center" style={{ ...webStyle.tabledatastyle, padding: '0px' }}>{this.dateToString(row.data.attributes.check_out_date)}</TableCell>

                    <TableCell align="center" style={{ ...webStyle.tabledatastyle, padding: '0px' }}>
                      {this.getDogCount(row.data.attributes.number_of_dogs)}{row.data.attributes.number_of_dogs > 0 && row.data.attributes.number_of_cats > 0 && <br />}
                      {this.getCatCount(row.data.attributes.number_of_cats)}
                    </TableCell>


                    {(this.state.buttonstatus === "accepted" || this.state.buttonstatus === "pending") ? <TableCell align="center"
                      style={{ ...webStyle.tabledatastyle, padding: '0px' }}
                    >
                      {this.state.totalDays[index]} day(s)
                    </TableCell> : null}

                    <TableCell align="center" style={{ ...webStyle.tabledatastyle, padding: '0px' }}>
                      {this.getPrice(row.data.attributes.price, Number(row.data.attributes.total_charges))}
                    </TableCell>
                    {this.state.buttonstatus != "ongoing" && <TableCell align="center" style={webStyle.editbutton} data-test-id="rowaction" onClick={this.getRowActionHandler(row)}>
                      {this.cusombutton()}
                    </TableCell>}
                    {renderMessageCell(row.data.attributes.chat_id[0])}
                  </TableRow>
                )
              })}
              {this.state.itemsList.length === 0 &&
                <TableRow>
                  <TableCell colSpan={8} align="center" style={{ height: 300, borderBottom: 'none' }}>
                    <Typography variant="h1" style={webStyle.hotelName}
                      data-test-id='no_reservation'
                    >{this.state.historyLoader ? "Searching" : configJSON.noReservation}</Typography>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </CustomTable>
        </TableContainer>
      </Grid>
    );
  };

  getRowActionHandler = (row: Reservations) => {

    return (this.state.buttonstatus === "past" || this.state.buttonstatus === "cancelled" || this.state.buttonstatus === "no_show") ?
      () => this.viewReservations(row.data) :
      () => this.onReservation(row.data.id)
  };



  getButtonStyle = (status: string) => {
    return this.state.buttonstatus === status
      ? webStyle.headbuttons2
      : webStyle.headbuttons1;
  };


  render() {


    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ width: "100%", padding: "0px" }}>
          <PetOwnerNavigationMenu navType="fullNavbar" navigation={this.props.navigation} id="" activeLink={6} />
          <MainTableBox sx={webStyle.mainContainer}>
            <Box style={webStyle.contentbox as React.CSSProperties}>

              <Grid item xs={12}>
                <Grid container justifyContent="flex-start" alignItems="center" spacing={2} className="buttonlayout">

                  <Button
                    style={this.getButtonStyle("accepted") as React.CSSProperties}
                    onClick={this.AcceptButton}
                    data-test-id="buttonstatus0"
                  >
                    Accepted
                  </Button>
                  <Button
                    style={this.getButtonStyle("pending") as React.CSSProperties}
                    onClick={this.pendingbutton}
                    data-test-id="buttonstatus1"
                  >
                    Pending
                  </Button>
                  <Button
                    style={this.getButtonStyle("ongoing") as React.CSSProperties}
                    onClick={this.ongoingButton}
                    data-test-id="ongoing_button"
                  >
                    Ongoing
                  </Button>
                  <Button
                    style={this.getButtonStyle("past") as React.CSSProperties}
                    onClick={this.pastbutton}
                    data-test-id="buttonstatus2"
                  >
                    Past
                  </Button>
                  <Button
                    style={this.getButtonStyle("cancelled") as React.CSSProperties}
                    onClick={this.canceledbutton}
                    data-test-id="buttonstatus4"
                  >
                    Cancelled
                  </Button>
                  <Button
                    style={this.getButtonStyle("no_show") as React.CSSProperties}
                    onClick={this.noShowButton}
                    data-test-id="no_show_button"
                  >
                    No Show
                  </Button>
                </Grid>

              </Grid>

              {this.customTable()}

            </Box>

          </MainTableBox>

          {this.state.viewModal &&
            <ReservationDailog navigation={undefined} id={""} open={this.state.viewModal} dailogType={"view"} getDailogStatus={this.closeModal} getRejectAction={() => { }} getRejectMessage={() => { }} petDetails={{} as PetAttributes} getFilterParams={() => { }} reservationDetails={this.state.reservationDetails} upcomingStatusAction={() => { }} upcomingIndex={0} saveOrderId={""} listOfRooms={[]} roomListBo={[]} openRoomTypes={false} params={undefined}
              selectedRoomType={""} selectedRoomCount={0} openCalenderStatus={false} inputFieldDate={""} selectRoomType={() => { }} handleRoomSelect={() => { }} openRoomType={() => { }} closeRoomTypes={() => { }} closeSelectDate={() => { }} clearDates={() => { }} openSelectDate={() => { }} handleDateChange={() => { }} addPets={() => { }} minusPets={() => { }} handlePriceInput={() => { }} selectedRange={[]} numberOfPets={0} price={{
                min: "",
                max: ""
              }} handleClear={() => { }} closeDate={() => { }} paymentDetails={{} as PaymentDetails} popUpBtnText={""}
              openCommonDailog={() => { }} />
          }


        </Container>
        <Footer color="#CCEDFB" footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />
        <Loader loading={this.state.historyLoader} />
      </ThemeProvider>
    );
  }
}

const CustomTable: any = styled(Table)({
  marginBottom: "40px",
  "& tr": {
    boxShadow: "rgba(67, 134, 161, 0.15) 0px 4px 8px 0px",
    height: "68px",
    margin: "10px 0px"
  },
  "& th": {
    borderBottom: "1px solid #65C9F2"
  },
  "& td": {
    borderBottom: "1px solid #65C9F2"
  }
})

const webStyle = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#CCEDFB",
    minHeight: "100vh",
  },
  contentbox: {
    backgroundColor: "#fff",
    width: "80%",
    // height:"300px",
    borderRadius: "20px",
    marginTop: "95px",
    padding: "20px"
  },
  buttonbox: {
    // backgroundColor:"yellow",
    height: "100px",
    display: "flex",
    alignItems: "center"
  },
  headbuttons1: {
    height: "44px",
    borderRadius: "26px",
    padding: "10px 20px",
    marginRight: "15px",
    border: "solid 1px #B2E4F8",
    fontSize: "14px",
    fontWeight: 400,
    textTransform: "none"
  },
  headbuttons2: {
    height: "44px",
    borderRadius: "26px",
    padding: "10px 20px",
    marginRight: "15px",
    backgroundColor: "#4386A1",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "none",
    color: "#fff"
  },
  tableheadcell: {
    color: "#4386A1",
    fontSize: "14px",
    fontWeight: 700
  },
  tabledatastyle: {
    fontSize: "16px",
    fontWeight: 700,

  },
  editbutton: {
    fontSize: "16px",
    fontWeight: 700,
    textDecorationLine: "underline",
    color: "#EA0C78",
    cursor: "pointer"
  },
  viewmodalinnerbox: {
    width: "47%",
    height: "95%",
    // background: "yellow",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  modalimg: {
    width: 300,
    height: 188
  },
  veiwhotelname: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#000"
  },
  hotelName: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1rem"
  },
  locationName: {
    fontWeight: theme.typography.fontWeightRegular,
    minHeight: 24
  },
  hotelTitle: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    whiteSpace: "nowrap",
    textOverflow: 'ellipsis'
  },
  imageBackground: {
    background: theme.palette.primary.main,
    borderRadius: '8px 0px 0px 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
    maxHeight: "100%",
  },
  overFlowWhiteSpace: {
    overflow: 'hidden',
    whiteSpace: "nowrap",
    textOverflow: 'ellipsis'
  }
};


const MainTableBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBottom: "30px",
  background: "#CCEDFB",
  minHeight: "100vh",
  "& .buttonlayout": {
    height: '100px',
    [theme.breakpoints.down(600)]: {
      justifyContent: 'center'
    }
  }

}));

// Customizable Area End