import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";

interface LinkObject {
  url: string;
  title: string;
}
interface RoomAvailability {
  name: string;
  total: number;
  available: number;
}

interface RoomBlock {
  [date: string]: RoomAvailability[] | string;
}

interface HotelAttributes {
  name: string;
  cover_photo: string;
  dog_rooms: RoomBlock[];
  cat_rooms: RoomBlock[];
}

export interface Hotel {
  id: string;
  type: string;
  attributes: HotelAttributes;
}

interface HotelsData {
  data: Hotel[];
}

interface Rooms {
  [key: string]: boolean;  
  allRooms?: boolean | any;
}

export interface CalendarData {
  id:string
attributes?: any;
hotels: HotelsData
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: string;
  timeSlots: object[];
  serviceProviderId: string;
  serviceProviderSchedule: any;
  details: any;
  token: any;
  currentDate: Date,
  isWeek: boolean,
  calendarData: CalendarData[],
  calendarDayData: CalendarData[]
  pagLoader: boolean,
  selectedPet: string;
  selectedProperty: string;
  selectedRoom: any;
  filteredData: CalendarData[],
  socialLinksData: {icons: string, navigationUrl: string}[];
  poBOLinksData: LinkObject[];
  open: boolean,
  isUnique: (string | number)[]; 
  rooms: Rooms;
  anchorEl: HTMLElement | null; 
  tempRooms: Rooms,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  serviceProviderDetailApiId:any;
  serviceProviderScheduleApiId:any;
  apiCallIdCalendarData: string = '';
  apiCallIdCalendarDayData: string = '';
  apiCallIdSearchHotelsList:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage), 
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      selectedTime: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: "",
      details: {},
      token: null,
      currentDate: new Date(),
      isWeek: true,
      calendarData: [],
      calendarDayData: [],
      pagLoader: false,
      selectedPet:"All Pets",
      selectedProperty: "All Properties",
      filteredData: [],
      socialLinksData: [],
      poBOLinksData:[],
      selectedRoom: ['All Rooms'],
      open: false,
      isUnique: [],
      rooms: {},
      anchorEl : null,
      tempRooms: {},
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.apiCallIdCalendarData) {
      this.CalendarDataApiRes(responseJson)

  }
  if (apiRequestCallId === this.apiCallIdCalendarDayData) {
    this.CalendarDayDataApiRes(responseJson) } 
  if (apiRequestCallId === this.apiCallIdSearchHotelsList) {
    this.filterDataApiRes(responseJson)
}
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.filterHotelsList()
    if (this.state.isWeek) {
      this.getCalendarData();
  } else {
      this.getCalendarDayData();
  }
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks",true)
    this.setState({poBOLinksData: allLinksData})
  }
  componentDidUpdate(prevProps: Props , prevState: S) {
    const { rooms, isUnique } = this.state;
    if (prevState.rooms !== rooms) {
      const allSelected = isUnique.every((room: string | number) => rooms[room] === true);
      if (allSelected !== rooms.allRooms) {
        this.setState((prevState) => ({
          rooms: { ...prevState.rooms, allRooms: allSelected }
        }));
      }
    }}
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  CalendarDataApiRes = (responseJson: {errors: [], message: string, hotels: {data : []}}) => {
    if (responseJson && !responseJson.errors && !responseJson.message) {
        this.setState({ calendarData: responseJson?.hotels?.data, pagLoader: false })
    }

  }
  CalendarDayDataApiRes = (responseJson: {errors: [], message: string, hotels: {data : []}}) => {
    if (responseJson && !responseJson.errors && !responseJson.message) {
        this.setState({ calendarDayData: responseJson?.hotels?.data, pagLoader: false })
    }
  }
  filterDataApiRes = (responseJson: { errors: []; message: string; hotels: { data: [] }; }) => {
    if (responseJson && !responseJson.errors && !responseJson.message) {
      this.setState({ filteredData: responseJson?.hotels?.data, pagLoader: false })
      this.getUniqueRoomNames()
  }
  }
  
  onSelectDate = (selectedDateStr: string) => {
    const {details} = this.state;
    this.setState({
      selectedDate: selectedDateStr,
      timeSlots: [],
      selectedTime: 0
    });
    this.getServiceProviderAvailability(selectedDateStr, details);
  };

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate)
  };

  async getServiceProviderAvailability(selectedDateStr: any, profileData: any){
    const token = this.state.token || '';
    if(profileData && profileData.id && selectedDateStr){
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: profileData.id,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token
      })
    } else if(this.state.serviceProviderId && selectedDateStr){
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: this.state.serviceProviderId,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token
      })
    }
  }

  async getServiceProviderDetails(dataObj: any) {
    const { setApiCallId, serviceProviderId, availableDate, token } = dataObj;

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token,
    };
    
    this.apiCall({
      setApiCallId,
      header,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.serviceProviderAPiEndPoint}?availability_date=${availableDate}&service_provider_id=${serviceProviderId}`,
      body: null
    });
    return true;
  }

  apiCall = async (data: any) => {

    const { setApiCallId, header, endPoint, method, body } = data;
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if(setApiCallId === 'serviceProviderDetailApiId'){
      this.serviceProviderDetailApiId = requestMessage.messageId;
    }else if(setApiCallId === 'serviceProviderScheduleApiId'){
      this.serviceProviderScheduleApiId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unsubscribeMessages = () => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  };

  formatDate = (date: { toLocaleDateString: (arg0: undefined, arg1: { year: string; month: string; day: string; }) => any; }) => {
      return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
  };

  formatDateWithoutYear = (date: Date) => {
    return date.toLocaleDateString(undefined, {
      month: 'long',
      day: 'numeric',
    });
  };

  handleDateChange = (value: number) => {
    this.setState((prevState) => {
      const newDate = new Date(prevState.currentDate);
      if (this.state.isWeek) {
        newDate.setDate(newDate.getDate() + (value * 7));
      } else if(!this.state.isWeek) {
        newDate.setDate(newDate.getDate() + value);
      }
      return { currentDate: newDate };
    }, () => {
      if (this.state.isWeek) {
        this.getCalendarData();
    } else {
        this.getCalendarDayData();
    }
    });
  };

  getFormattedDay = (date: string | number | Date, daysToAdd: number) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + daysToAdd);
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const adjustedDay = (newDate.getDay() + 6) % 7; 
    return {
      dayOfWeek: daysOfWeek[adjustedDay],
      dayOfMonth: newDate.getDate()
    };
  };

  getWeekRange = (date: Date) => {
    const startOfWeek = new Date(date);
    const day = (startOfWeek.getDay() + 6) % 7; 
    const diff = -day; 
    startOfWeek.setDate(date.getDate() + diff);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    return `${this.formatDateWithoutYear(startOfWeek)} - ${this.formatDate(endOfWeek)}`;
  };

  handleTodayClick = () => {
    this.setState({ currentDate: new Date() },()=>{
      if (this.state.isWeek) {
        this.getCalendarData();
      } else {
        this.getCalendarDayData();
      }
    });
  };

  handleViewChange = (view: string) => {
    this.setState((prevState) => {
      const newDate = new Date();
      if (view === "week") {
        newDate.setDate(newDate.getDate() - newDate.getDay()); 
      }
      return {
        isWeek: view === "week",
        currentDate: newDate
      };
    }, () => {
        if (this.state.isWeek) {
            this.getCalendarData();
        } else {
            this.getCalendarDayData();
        }
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleApply = () => {
    this.setState((prevState) => ({
      rooms: { ...prevState.tempRooms },
    }), () => {
      this.handleClose();
      if (this.state.isWeek) {
        this.getCalendarData();
      } else {
        this.getCalendarDayData();
      }
    });
  };
  getSelectedRoomsLabel = () => {
    const { rooms, isUnique } = this.state;
    const selectedRooms = isUnique.filter((room) => rooms[room]);
    if (selectedRooms.length === isUnique.length) return 'All Rooms';
    if (selectedRooms.length > 0) return `Room Type (${selectedRooms.length})`;
    return 'No Rooms Selected';
  };

  getTempSelectedRoomsLabel = () => {
    const { tempRooms, isUnique } = this.state;
    const selectedRooms = isUnique.filter((room) => tempRooms[room]);
    if (selectedRooms.length === isUnique.length) return 'All Rooms';
    if (selectedRooms.length > 0) return `Room Type (${selectedRooms.length})`;
    return 'No Rooms Selected';
  };
  
  getSelectedRooms = (rooms: { [key: string]: boolean }): { allRoomsArray: string[], selectedRooms: string[] } => {
    const allRoomsArray: string[] = [];
    const selectedRooms: string[] = [];
    if (rooms["allRooms"]) {
        allRoomsArray.push("All Rooms");
    }

    Object.entries(rooms).forEach(([roomName, isSelected]) => {
        if (isSelected && roomName !== "allRooms") {
            selectedRooms.push(roomName);
        }
    });

    return { allRoomsArray, selectedRooms };
};
  
  getCalendarData = async () => {
    const { currentDate, rooms} = this.state;

    const { allRoomsArray, selectedRooms } = this.getSelectedRooms(rooms);
    const isSelectedRoom = allRoomsArray.length > 0 ? allRoomsArray : selectedRooms;
    let startDateParam, endDateParam;
    const propertyNameParam = this.state.selectedProperty && this.state.selectedProperty !== "All Properties" 
    ? `hotel_id=${this.state.selectedProperty}` 
    : '';
    const petNameParam = this.state.selectedPet && this.state.selectedPet !== "All Pets" 
        ? `&pet_type=${this.state.selectedPet}` 
        : '';
        const roomNameParam = isSelectedRoom.length > 0 && !isSelectedRoom.includes("All Rooms")
        ? isSelectedRoom.map((room) => `&room_type[]=${room}`).join('')
        : '';

        const startOfWeek = new Date(currentDate);
        const dayOfWeek = startOfWeek.getDay();
        const diff = startOfWeek.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Sunday to start from Monday
        startOfWeek.setDate(diff);
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        startDateParam = `${startOfWeek.getFullYear()}-${(startOfWeek.getMonth() + 1).toString().padStart(2, '0')}-${startOfWeek.getDate().toString().padStart(2, '0')}`;
        endDateParam = `${endOfWeek.getFullYear()}-${(endOfWeek.getMonth() + 1).toString().padStart(2, '0')}-${endOfWeek.getDate().toString().padStart(2, '0')}`;

    let tokenValue = await getStorageData("authToken");
    const headers = {
        token: tokenValue,
    };
    

    this.setState({ pagLoader: true })
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.filterCalendarEndPoint}/hotels_filter?type=week&${propertyNameParam}&start_date=${startDateParam}&end_date=${endDateParam}${petNameParam}${roomNameParam}`
        );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType
    );
    this.apiCallIdCalendarData = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

      getCalendarDayData = async () => {
        const { currentDate,rooms } = this.state;

        const { allRoomsArray, selectedRooms } = this.getSelectedRooms(rooms);
        const isSelectedRoom = allRoomsArray.length > 0 ? allRoomsArray : selectedRooms;
        const propertyNameParam = this.state.selectedProperty && this.state.selectedProperty !== "All Properties" 
        ? `hotel_id=${this.state.selectedProperty}` 
        : '';
        const petNameParam = this.state.selectedPet && this.state.selectedPet !== "All Pets" 
            ? `&pet_type=${this.state.selectedPet}` 
            : '';
            const roomNameParam = isSelectedRoom.length > 0 && !isSelectedRoom.includes("All Rooms")
            ? isSelectedRoom.map((room) => `&room_type[]=${room}`).join('')
            : '';
        let dateParam;
        dateParam = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
        let tokenValue = await getStorageData("authToken");
        const headers = {
            token: tokenValue,
        };
        

        this.setState({ pagLoader: true })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

      
        requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.filterCalendarEndPoint}/hotels_filter?type=day&${propertyNameParam}&date=${dateParam}${petNameParam}${roomNameParam}`
            );
        
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType
        );
        this.apiCallIdCalendarDayData = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

  handlePetChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const petType = event.target.value as string;
    this.setState({ selectedPet: petType }, () => {
        if (this.state.isWeek) {
            this.getCalendarData();
          } else {
            this.getCalendarDayData();
          }
        });
  };
  handlePropertyChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const propertyName = event.target.value as string;
    this.setState({ selectedProperty: propertyName }, () => {
          if (this.state.isWeek) {
            this.getCalendarData();
          } else {
            this.getCalendarDayData();
          }
        });
  };

  handleRoomChange = (event: { target: { name: string; checked: boolean; }; }) => {
    const { name, checked } = event.target;
    this.setState((prevState) => {
      const updatedTempRooms = {
        ...prevState.tempRooms,
        [name]: checked,
      };
      if (!checked) {
        updatedTempRooms.allRooms = false;
      }
      const allRoomsSelected = prevState.isUnique.every((room) => updatedTempRooms[room]);
      if (allRoomsSelected) {
        updatedTempRooms.allRooms = true;
      }
      return { tempRooms: updatedTempRooms };
    });
  };
  handleAllRoomsChange = (event: { target: { checked: boolean; }; }) => {
    const { checked } = event.target;
    const updatedRooms:Rooms  = this.state.isUnique.reduce((acc:Rooms , room) => {
      acc[room] = checked;
      return acc;
    }, { allRooms: checked });
    this.setState({
      tempRooms: updatedRooms,
    });
  };



  filterHotelsList = async () => {
    let startDateParam, endDateParam;
    const { currentDate } = this.state;
    const startOfWeek = new Date(currentDate);
    const dayOfWeek = startOfWeek.getDay();
    const diff = startOfWeek.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Sunday to start from Monday
    startOfWeek.setDate(diff);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    endDateParam = `${endOfWeek.getFullYear()}-${(endOfWeek.getMonth() + 1).toString().padStart(2, '0')}-${endOfWeek.getDate().toString().padStart(2, '0')}`;
    startDateParam = `${startOfWeek.getFullYear()}-${(startOfWeek.getMonth() + 1).toString().padStart(2, '0')}-${startOfWeek.getDate().toString().padStart(2, '0')}`;

    let authToken= await getStorageData("authToken");
    const headers = {
        token: authToken,
    };
  let endpoints = `${configJSON.filterCalendarEndPoint}/hotels_filter?type=week&start_date=${startDateParam}&end_date=${endDateParam}`

    this.setState({ pagLoader: true })
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoints
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );
    this.apiCallIdSearchHotelsList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

getUniqueRoomNames = () => {
  const roomNames = new Set<string>();
  this.state.filteredData.forEach((hotel) => {
    hotel?.attributes.dog_rooms.forEach((roomData: RoomBlock) => {
      Object.values(roomData).forEach((roomList) => {
        if (Array.isArray(roomList)) {
          roomList.forEach((room: RoomAvailability) => roomNames.add(room.name));
        }
      });
    });
  
    hotel?.attributes.cat_rooms.forEach((roomData: RoomBlock) => {
      Object.values(roomData).forEach((roomList) => {
        if (Array.isArray(roomList)) {
          roomList.forEach((room: RoomAvailability) => roomNames.add(room.name));
        }
      });
    });
  });
  
  const unique = Array.from(roomNames)
  const initialState = unique.reduce((acc, room) => ({ ...acc, [room]: true }), { allRooms: true });
  this.setState({ isUnique: unique, rooms: initialState });
};

handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  this.setState({
    anchorEl: event?.currentTarget,
    tempRooms: { ...this.state.rooms },
  });
};

  // Customizable Area End
}