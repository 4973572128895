import * as React from "react";
// Customizable Area Start
import { StyleSheet, View } from "react-native"
import { ViewProps } from "./StripePayments.web";
import {
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Grid, Typography, Divider, makeStyles, createStyles, Theme, Button } from '@material-ui/core';
import { payments } from "../../shoppingcart/src/assets";
export const configs = require("./config");
import { CustomCheckboxGrey, CustomCheckboxSecondary, CustomizedTextFieldAutoComplete } from "../../../components/src/CustomizedComponents.web";
import { CancellationPolicyData } from "./types";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Loader from "../../../components/src/Loader";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
export const configJSON = require("./config");

// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headingText: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black
    },
    dividerRoot: {
      backgroundColor: theme.palette.info.main
    },
    headingBlack: {
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightRegular
    },
    cardNumberLayout: {
      height: 72,
      border: `1px solid ${theme.palette.info.main}`,
      borderRadius: 8
    },
    placeHolderTitle: {
      color: theme.palette.text.disabled,
      fontWeight: theme.typography.fontWeightRegular
    },
    'input': {
      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightRegular,
        opacity: 10,
        fontSize: 20
      }
    },
    cardLayout: {
      marginTop: 30,
      marginBottom: 30
    },
    percentText: {
      color: "#334155",
      padding: '0 0 0 20px',
      fontWeight: theme.typography.fontWeightRegular

    },
    agreeText: {
      color: "#94A3B8",
      fontWeight: theme.typography.fontWeightRegular
    },
    activeText: {
      color: "#1E293B",
      fontWeight: theme.typography.fontWeightRegular,
      textDecoration: 'none',
      borderBottom: '1px solid #1E293B'
    },
    subscriptionText: {
      color: "#475569",
      fontWeight: theme.typography.fontWeightBold,
    },
    subscriptionDetailsText: {
      color: "#475569",
      fontWeight: theme.typography.fontWeightRegular,
    },
    bookButton: {
      textTransform: 'capitalize',
      height: 56,
      borderRadius: 8,
      fontSize: 16,
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    payNowButton: {
      width: '210px',
      textTransform: 'capitalize',
      height: 56,
      borderRadius: 8,
      fontSize: 16,
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
  })
);
const cardStyle = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },

};

interface RenderProps {
  getCardTokenDetails: Function
  policyType?: CancellationPolicyData;
  testID: string;
  orderPrice: string;
  paymentPageType?: string,
  backSubcription?: () => void,
  paymentTriggered?: () => void
  pageComingFrom?: string;
  loading: boolean,
  errorString: string,
  toasterStatus: boolean,
  getTermsAndConditionValues?: (keys1: boolean, keys2: boolean) => void;
  stripeTermsConditions?: string;
  closeToaster: () => void;
  paymentType: string
}
export const PaymentRender: React.FC<RenderProps> = ({
  getCardTokenDetails,
  policyType,
  testID,
  orderPrice,
  paymentPageType,
  backSubcription,
  paymentTriggered,
  pageComingFrom,
  loading,
  errorString,
  toasterStatus,
  getTermsAndConditionValues,
  stripeTermsConditions,
  closeToaster,
  paymentType


}) => {

  const [accountName, setAccountName] = React.useState<string>('')
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [termCheckStatus, setTermCheckStatus] = React.useState<boolean>(false)
  const [stripeTermsCheck, setStripeTermsCheck] = React.useState<boolean>(false)
  const [petHotelTermsCheck, setPetHotelTermsCheck] = React.useState<boolean>(false)

  const [accountNameError, setAccountNameError] = React.useState<string>('')
  const [termsError, setTermsError] = React.useState<string>('')
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();




  const handleSubmit = async () => {
    const hasError = validateForm();

    if (hasError || !stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    if (!cardNumberElement) {
      return;
    }

    const { error, token } = await stripe.createToken(cardNumberElement, {
      name: accountName
    });
    if (error) {
      setErrorMessage("Please Enter Proper card Details")
    } else if (token) {
      if (paymentTriggered) {
        paymentTriggered()
        if (getTermsAndConditionValues) {
          getTermsAndConditionValues(stripeTermsCheck, petHotelTermsCheck)
        }
      }
      getCardTokenDetails(token);
      setErrorMessage("");
    }
  };

  const validateForm = () => {
    let hasError = false;

    if (!accountName) {
      setAccountNameError('Cardholder name is required.');
      hasError = true;
    } else {
      setAccountNameError('');
    }

    if (!stripeTermsCheck || !petHotelTermsCheck) {
      setTermsError('You must agree to the terms and conditions.')
      hasError = true;

    } else {
      setTermsError('');
    }

    return hasError;
  };
  const handleAccountNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAccountName = event.target.value;
    const nameRegex = /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/;


    if (!newAccountName) {
      setAccountNameError('Cardholder name is required.');
    } else if (!nameRegex.test(newAccountName)) {
      setAccountNameError('Invalid cardholder name. Only letters and spaces are allowed.');
    } else {
      setAccountNameError('');
    }

    setAccountName(newAccountName);
  };

  const handleStripeTermsCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStripeTermsCheck(event.target.checked)

  }
  const handlePetHotelTermsCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPetHotelTermsCheck(event.target.checked)

  }

  const getOrderPrice = (price: string) => {
    return "£" + Number(price.split("£")[1]).toFixed(2);
  }
  return (
    <>
      <CustomizedSnackbars openToaster={toasterStatus} toasterType={'error'} message={errorString} duration={2000} closeStatus={closeToaster} />
      <Grid container direction="row" spacing={3} className={classes.cardLayout}>
       {paymentType===configJSON.cardPayment&& <Grid item xs={12}>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={2}>
              <Typography variant="body1" className={classes.headingText}>Pay with</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Divider className={classes.dividerRoot} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <img src={payments} width={239} height={29} alt="Payment methods" />
            </Grid>
          </Grid>
        </Grid>}
        {policyType && policyType?.attributes?.cancellation_type == 'standard' &&
          <>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.headingBlack} >
                      This PETHOTEL requires an upfront payment of  {policyType.attributes.pre_payment_percent}% of the total booking amount.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} >
                  <Grid container alignItems="center" >
                    <Grid item xs={12} sm={9} container alignItems="center">
                      <CustomCheckboxSecondary onChange={undefined} name={""} checked={true}
                      />
                      <Typography variant="subtitle2" className={classes.percentText} data-test-id='payment_percentage'>Pay({policyType.attributes.pre_payment_percent}%)</Typography>

                    </Grid>
                    <Grid item xs={12} sm={3} >
                      <Grid container justifyContent="flex-end">
                        <Grid item xs={12} className={classes.cardNumberLayout} container alignItems="center"
                          justifyContent="center"
                        >
                          <Typography variant="h6" className={classes.headingText} >{getOrderPrice(orderPrice)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
        {policyType && policyType?.attributes?.cancellation_type == 'tailored' &&
          <>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {policyType.attributes.charge_option === "deposit" &&
                    <Typography variant="subtitle2" className={classes.headingBlack} >
                      To secure your booking, the PETHOTEL require an upfront deposit of {orderPrice}
                    </Typography>

                  }

                  {policyType.attributes.charge_option === "pre_payment" &&
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" className={classes.headingBlack} >
                        This PETHOTEL requires an upfront payment of  {policyType.attributes.pre_payment_percent}% of the total booking amount.
                      </Typography>
                    </Grid>
                  }
                </Grid>
                {policyType.attributes.charge_option === "pre_payment" &&

                  <Grid item xs={12} >
                    <Grid container alignItems="center" >
                      <Grid item xs={12} sm={9} container alignItems="center">
                        <CustomCheckboxSecondary onChange={undefined} name={""} checked={true}
                        />
                        <Typography variant="subtitle2" className={classes.percentText} >Pay({policyType.attributes.pre_payment_percent}%)</Typography>

                      </Grid>
                      <Grid item xs={12} sm={3} >
                        <Grid container justifyContent="flex-end">
                          <Grid item xs={12} className={classes.cardNumberLayout} container alignItems="center"
                            justifyContent="center"
                          >
                            <Typography variant="h6" className={classes.headingText} >{getOrderPrice(orderPrice)}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>}
              </Grid>
            </Grid>
          </>

        }
        {paymentType===configJSON.cardPayment&&<>
          <Grid item xs={12} container alignItems="center" className={classes.cardNumberLayout}>
            <Typography variant="body2" className={classes.placeHolderTitle}>
              Card number
            </Typography>
            <div style={{ margin: '10px 0', width: '100%' }}>
              <CardNumberElement options={cardStyle} />
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15, width: "100%" }}>
            <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
              <Grid item xs={12} sm={6} style={{ maxWidth: '49%' }} container alignItems="center" className={classes.cardNumberLayout}>
                <Typography variant="body2" className={classes.placeHolderTitle}>
                  Expiration
                </Typography>
                <div style={{ margin: '10px 0', width: '100%' }}>
                  <CardExpiryElement options={cardStyle} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}
                style={{ maxWidth: '49%' }}
                container alignItems="center" className={classes.cardNumberLayout}>
                <Typography variant="body2" className={classes.placeHolderTitle}>
                  CVC
                </Typography>
                <div style={{ margin: '10px 0', width: '100%' }}>
                  <CardCvcElement options={cardStyle} />
                </div>
              </Grid>
              <Grid item xs={12} container alignItems="center" className={classes.cardNumberLayout} style={{ margin: '15px 0' }}>

                <CustomizedTextFieldAutoComplete
                  placeholder="Cardholder name"
                  variant="outlined"
                  fullWidth
                  onChange={handleAccountNameChange}
                  data-test-id='account_name'
                  value={accountName}
                  InputProps={{ classes: { input: classes['input'] } }}
                  error={Boolean(accountNameError)}

                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography color="error" variant="subtitle2">{accountNameError}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={12} container>
              <Grid container alignItems="center">
                <Grid item>
                  <CustomCheckboxGrey
                    onChange={handleStripeTermsCheckBox}
                    checked={stripeTermsCheck} name={"stripe_check"} />&nbsp;&nbsp;

                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Typography variant="body1" className={classes.agreeText} style={{ display: 'inline' }}>
                    I have read and agree to the&nbsp;
                    <Typography
                      variant="body1"
                      component="a"
                      target="_blank"
                      href={stripeTermsConditions}
                      className={classes.activeText}
                      style={{ display: 'inline' }}
                    >
                      Terms and Conditions
                    </Typography>
                    ,&nbsp;which include payments processed by Stripe under Stripe’s Services Agreement
                  </Typography>

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="center" style={{ marginTop: 30 }}>
              <CustomCheckboxGrey
                onChange={handlePetHotelTermsCheckBox}
                checked={petHotelTermsCheck} name={"pethotel_check"} />&nbsp;&nbsp;
              <Typography variant="body1" className={classes.agreeText}>I have read and agree to the PETHOTEL</Typography>&nbsp;
              <Typography variant="body1" component={'a'} target="_blank" href={'/PrivacyPolicy'}
                className={classes.activeText}
              >Privacy Policy</Typography>&nbsp;
              <Typography variant="body1" className={classes.agreeText}>and</Typography>&nbsp;
              <Typography variant="body1" component={'a'} target="_blank" href={'/TermsConditions'}
                className={classes.activeText}
              >Terms and Conditions</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="error" data-test-id='terms_error'>{termsError}</Typography>
            <Typography variant="subtitle2" color="error">{errorMessage}</Typography>
          </Grid>

          {paymentPageType === 'subscription' ?
            <Grid item xs={12} >
              <Grid container direction="row" justifyContent={pageComingFrom !== "propertyDetails" ? "space-between" : "flex-end"} alignItems="center">
                {pageComingFrom !== "propertyDetails" &&
                  <Grid item data-test-id='back_btn' onClick={backSubcription} style={{ cursor: 'pointer' }}>
                    <Typography variant="subtitle2" className={classes.headingText} style={{ display: 'flex' }} >
                      <ArrowBackIosIcon /> &nbsp; Back</Typography>
                  </Grid>}
                <Grid item>
                  <Button fullWidth variant="contained"
                    data-test-id='button_submit'
                    color="secondary"
                    className={classes.payNowButton}
                    onClick={handleSubmit}

                  >Pay Now
                  </Button>
                </Grid>
              </Grid>

            </Grid>
            :
            <Grid item xs={12} >
              <Button fullWidth variant="contained"
                // data-test-id='button_submit'
                color="secondary"
                className={classes.bookButton}
                data-test-id="book_now"
                onClick={handleSubmit}

              >Pay Now
              </Button>
            </Grid>}
        </>}
      </Grid>
      <Loader loading={loading} />
    </>
  );
};
// Customizable Area End


// Customizable Area Start
const StripePaymentsView: React.FC<ViewProps> = ({
  // Customizable Area Start
  testID,
  stripePromise,
  stripeClientSecret,
  errorString,
  setOrderNumber,
  orderNumber,
  submitOrderNumber,
  actionResult,
  stripeInitialised,
  isInStripeCallback,
  onHandleSubmit,
  submitOrderNumberButtonViewProps,
  submitPaymentButtonViewProps,
  loadingViewProps,
  orderIdViewProps,
  stripeMessageViewProps,
  getCardTokenDetails,
  policyType,
  orderPrice,
  paymentPageType, backSubcription,
  paymentTriggered,
  getTermsAndConditionValues,
  pageComingFrom,
  loading,
  toasterStatus,
  stripeTermsConditions,
  closeToaster,
  paymentType
  // Customizable Area End
}) => {
  // Customizable Area Start

  const Render: React.FC<ViewProps> = ({
    testID,
    errorString,
    setOrderNumber,
    orderNumber,
    actionResult,
    stripeInitialised,
    isInStripeCallback,
    onHandleSubmit,
    submitOrderNumberButtonViewProps,
    submitPaymentButtonViewProps,
    orderIdViewProps,
    stripeMessageViewProps,
  }) => {
    // Customizable Area Start
    const stripe = useStripe();
    const stripeElements = useElements();


    // Customizable Area End
    // Customizable Area Start
    return (
      <View style={webStyle.mainWrapper}>
        <Typography>{loadingViewProps.value}</Typography>
      </View>
    );
    // Customizable Area End
  };
  // Customizable Area End
  // Customizable Area Start
  const viewProps: ViewProps = {
    testID: testID,
    errorString,
    stripePromise: stripePromise,
    stripeClientSecret: stripeClientSecret,
    setOrderNumber: setOrderNumber,
    orderNumber: orderNumber,
    submitOrderNumber: submitOrderNumber,
    actionResult: actionResult,
    stripeInitialised: stripeInitialised,
    isInStripeCallback: isInStripeCallback,
    onHandleSubmit: onHandleSubmit,
    submitOrderNumberButtonViewProps,
    submitPaymentButtonViewProps,
    loadingViewProps,
    orderIdViewProps,
    stripeMessageViewProps,
    getCardTokenDetails: getCardTokenDetails,
    policyType: policyType,
    orderPrice: "",
    paymentPageType: paymentPageType,
    backSubcription: backSubcription,
    paymentTriggered: paymentTriggered,
    getTermsAndConditionValues: getTermsAndConditionValues,
    pageComingFrom: pageComingFrom,
    loading,
    toasterStatus,
    stripeTermsConditions: stripeTermsConditions,
    closeToaster,
    paymentType
  };
  // Customizable Area End

  return (
    // Customizable Area Start
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: stripeClientSecret }}
      key={stripeClientSecret}
    >
      <PaymentRender getCardTokenDetails={getCardTokenDetails}
        policyType={policyType}
        testID={testID} orderPrice={orderPrice}
        paymentPageType={paymentPageType}
        backSubcription={backSubcription}
        paymentTriggered={paymentTriggered}
        pageComingFrom={pageComingFrom}
        loading={loading}
        errorString={errorString}
        toasterStatus={toasterStatus}
        getTermsAndConditionValues={getTermsAndConditionValues}
        stripeTermsConditions={stripeTermsConditions}
        closeToaster={closeToaster}
        paymentType={paymentType}

      />
    </Elements>
    // Customizable Area End
  );
};
// Customizable Area End


// Customizable Area Start
const webStyle = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingTop: "32px",
    paddingBottom: "32px",
    paddingLeft: "32px",
    paddingRight: "32px",
    backgroundColor: "#fff",
  },
  flexWrapper: {
    flex: 1,
  },
  horizontalWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  submitButton: {
    backgroundColor: "#6200EE",
    color: "#fff",
  },
  cancelButton: {
    backgroundColor: "#FF0000",
  },
});

export default StripePaymentsView;
// Customizable Area End